import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export interface SaveIconProps {
  /** When isSaving is true, displays a spinner. */
  isSaving: boolean;
}

export default function SaveIcon({ isSaving }: SaveIconProps) {
  if (isSaving) return <FontAwesomeIcon icon={faCircleNotch} spin />;
  return <FontAwesomeIcon icon={faSave} />;
}
