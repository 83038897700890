import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import TE911Location from "../../../models/TE911Location";
import USStates from "../../../models/UsStates";
import validatePhoneNumber from "../../../services/validatePhoneNumber";
import { InputField } from "../../atoms/InputField";
import Button from "../../atoms/Button";
import SaveIcon from "../SaveIcon";
import ResetIcon from "../ResetIcon";
import CancelIcon from "../CancelIcon";
import styles from "./LocationForm.module.css";
import Typeahead from "../../atoms/Typeahead";
import clsx from "clsx";
import ApiService from "../../../services/ApiService";
import LegalAlert from "../LegalAlert";

interface LocationFormProps {
  /** Change Save button to display spinner, disable certain elements. */
  submitting: boolean;
  location?: TE911Location;
  showResetButton?: boolean;
  /** If validatedAddress is true, display the warning message about address validation to the user. */
  validatedAddress?: boolean;
  /** Indicates if this is a new location and requires a phone number value to be input. */
  isNew?: boolean;
  /** Indicates if the form is contained in a dialog, if it is we change some CSS classes. */
  isDialog?: boolean;
  onSubmit?(location: TE911Location): Promise<boolean>;
  onCancel?(): void;
}

function LocationForm(props: LocationFormProps) {
  const user = new ApiService().getCurrentUser();
  const [location, setLocation] = useState<TE911Location>({
    subId: props.location ? props.location.subId ?? "" : "",
    phoneNumber: props.location ? props.location.phoneNumber ?? "" : "",
    phoneNumberFormatted: props.location
      ? props.location.phoneNumberFormatted ?? ""
      : "",
    address1: props.location ? props.location.address1 ?? "" : "",
    address2: props.location ? props.location.address2 ?? "" : "",
    additionalInfo: props.location ? props.location.additionalInfo ?? "" : "",
    city: props.location ? props.location.city ?? "" : "",
    state: props.location ? props.location.state ?? "" : "",
    zip: props.location ? props.location.zip ?? "" : "",
    bypassAddressValidation: "1",
  });

  useEffect(() => {
    setLocation({
      subId: props.location ? props.location.subId ?? "" : "",
      phoneNumber: props.location ? props.location.phoneNumber ?? "" : "",
      phoneNumberFormatted: props.location
        ? props.location.phoneNumberFormatted ?? ""
        : "",
      address1: props.location ? props.location.address1 ?? "" : "",
      address2: props.location ? props.location.address2 ?? "" : "",
      additionalInfo: props.location ? props.location.additionalInfo ?? "" : "",
      city: props.location ? props.location.city ?? "" : "",
      state: props.location ? props.location.state ?? "" : "",
      zip: props.location ? props.location.zip ?? "" : "",
      bypassAddressValidation: "1",
    });
  }, [props.location]);

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{ ...location }}
      validationSchema={Yup.object({
        subIdField: Yup.string().optional(),
        phoneNumber: Yup.string()
          .test(
            "phone",
            "Please enter a valid phone number.",
            validatePhoneNumber
          )
          .required(),
        address1: Yup.string().required("Please provide an address."),
        address2: Yup.string().optional(),
        additionalInfo: Yup.string()
          //Possible TODO:
          //Detailed location info + Address2 field cannot exceed 60 characters
          //The below code mostly accomplishes that, but does not update in
          // real time.
          //A current bug with the code is that the resultant string can be
          // more than 60 characters if both additional info and address2
          // are edited at the same time.
          .max(
            60 -
              (location.address2.length !== 0
                ? location.address2.length + 2
                : 0),
            "Detailed Location (+ address2) cannot exceed 60 characters"
          )
          .required("Please provide additional details."),
        city: Yup.string().required("Please provide a city."),
        state: Yup.string().required("Please provide a state."),
        zip: Yup.string().required("Please provide a zip code."),
      })}
      onSubmit={async (values, { setSubmitting }) => {
        location.subId = values.subId;
        location.phoneNumber = values.phoneNumber;
        location.phoneNumberFormatted = values.phoneNumberFormatted;
        location.address1 = values.address1;
        location.address2 = values.address2 ?? "";
        location.additionalInfo = values.additionalInfo ?? "";
        location.city = values.city;
        location.state = values.state;
        location.zip = values.zip;
        location.bypassAddressValidation = "1";
        setLocation({ ...location });
        if (props.onSubmit) return props.onSubmit(location);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        handleReset,
        setFieldTouched,
        setFieldValue,
      }) => (
        <form
          className={styles.form}
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(event)
          }
        >
          {!props.isDialog && (
            <span className={styles.title}>
              {props.isNew ? "New Location" : location.phoneNumberFormatted}
            </span>
          )}

          {props.validatedAddress && (
            <div className={clsx(styles.explanation)}>
              <div>
                The location has been updated with a verified address that is
                different than what you entered.
              </div>
              <div>
                If this address is not correct, please correct it and try again.
              </div>
            </div>
          )}

          {props.isNew && (
            <InputField
              name="phoneNumber"
              value={values.phoneNumber}
              label={!props.isDialog ? "Phone Number" : undefined}
              placeholder="Phone Number"
              aria-label="Phone Number"
              onBlur={handleBlur}
              onChange={handleChange}
              invalid={
                touched.phoneNumber !== undefined &&
                errors.phoneNumber !== undefined
              }
              hint={errors.phoneNumber}
            />
          )}

          <InputField
            name="address1"
            value={values.address1}
            label={!props.isDialog ? "Street Address" : undefined}
            placeholder="Street Address"
            aria-label="Street Address"
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={
              touched.address1 !== undefined && errors.address1 !== undefined
            }
            hint={errors.address1}
          />

          <InputField
            name="address2"
            value={values.address2}
            label={!props.isDialog ? "Suite / Apt / Office" : undefined}
            placeholder="Suite / Apt / Office"
            aria-label="Suite / Apt / Office"
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={
              touched.address2 !== undefined && errors.address2 !== undefined
            }
            hint={errors.address2}
          />

          <div className="grid grid-cols-3 lg:gap-5">
            <div className="col-span-3 lg:col-span-1">
              <InputField
                name="city"
                value={values.city}
                label={!props.isDialog ? "City" : undefined}
                placeholder="City"
                aria-label="City"
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={
                  touched.city !== undefined && errors.city !== undefined
                }
                hint={errors.city}
              />
            </div>

            <div className="col-span-3 lg:col-span-1">
              <Typeahead
                name="state"
                aria-label="State"
                label={!props.isDialog ? "State" : undefined}
                placeholder="State"
                value={USStates.find((s) => s.value === values.state)}
                onBlur={handleBlur}
                onChange={(value) => setFieldValue("state", value?.value)}
                options={USStates}
                isInvalid={
                  touched.state !== undefined && errors.state !== undefined
                }
                hint={errors.state}
              />
            </div>

            <div className="col-span-3 lg:col-span-1">
              <InputField
                name="zip"
                value={values.zip}
                label={!props.isDialog ? "Zip Code" : undefined}
                placeholder="Zip Code"
                aria-label="Zip Code"
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={touched.zip !== undefined && errors.zip !== undefined}
                hint={errors.zip}
              />
            </div>
          </div>

          <div className={styles.explanation}>
            <label>Detailed Location for 911</label>
            <p>
              The FCC requires detailed location information to assist first
              responders. In case of emergency which door should they knock
              down. This would include floor number, direction such as north
              wall or southeast corner, or any other information that would help
              locate the emergency.
            </p>
          </div>

          <InputField
            name="additionalInfo"
            value={values.additionalInfo}
            label={!props.isDialog ? "Detailed Location for 911" : undefined}
            placeholder="Detailed Location for 911"
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={
              touched.additionalInfo !== undefined &&
              errors.additionalInfo !== undefined
            }
            hint={errors.additionalInfo}
            //maxLength={60}
          />

          <LegalAlert variant="text-yellow" fullWidth />

          <div className={styles.buttons}>
            <Button
              variant="outline-green"
              label="Save"
              icon={<SaveIcon isSaving={props.submitting} />}
              disabled={
                props.submitting ||
                user?.role.localeCompare("AdminReadOnly") === 0
              }
              onClick={() => handleSubmit()}
            />

            {props.showResetButton && (
              <Button
                variant="outline-yellow"
                label="Reset"
                type="reset"
                icon={<ResetIcon />}
                disabled={props.submitting}
                onClick={() => handleReset()}
              />
            )}

            {props.onCancel && (
              <Button
                variant="outline-white"
                icon={<CancelIcon />}
                label="Cancel"
                disabled={props.submitting}
                onClick={() => handleCancel()}
              />
            )}
          </div>
        </form>
      )}
    </Formik>
  );
}

export default LocationForm;
