import React from "react";
import TE911Location from "../../../models/TE911Location";
import SearchBox from "../SearchBox";
import Button from "../../atoms/Button";
import ExportIcon from "../ExportIcon";
import UploadIcon from "../UploadIcon";
import NewIcon from "../NewIcon";
import styles from "./LocationList.module.css";
import LocationCard from "../LocationCard";
import { User } from "../../../models/User";

interface LocationListProps {
  locations: TE911Location[] | undefined;
  /** Indicates that an export is in progress, displays a spinner next to the export button. */
  isExporting: boolean;
  /** Indicates that an import is in progress, displays a spinner next to the import button. */
  isImporting: boolean;
  user: User | null;
  onAddLocationClick(): void;
  onExportClick(): void;
  onImportClick(): void;
  onSearchChange(search: string): void;
  onLocationSelected(location: TE911Location): void;
}

function LocationList(props: LocationListProps) {
  const renderLocations = () => {
    if (props.locations === undefined || props.locations.length === 0) {
      return (
        <div className="col-start-2 col-span-10 lg:col-start-3 lg:col-span-8">
          <div className="bg-gray-950 text-purple-1 border-2 border-gray-950 rounded-lg p-3 pt-5 pb-5 text-center">
            <span className="text-display-sm">No locations were found</span>
          </div>
        </div>
      );
    } else {
      return (
        <>
          {props.locations.map((location, key) => (
            <div
              key={key}
              className="col-start-2 col-span-10 lg:col-start-3 lg:col-span-8"
            >
              <LocationCard
                location={location}
                onClick={props.onLocationSelected}
              />
            </div>
          ))}
        </>
      );
    }
  };

  return (
    <>
      <div className={styles.toolbar}>
        <div className={styles.searchWrapper}>
          <SearchBox onChange={(search) => props.onSearchChange(search)} />
        </div>
        {props.user?.role.includes("AdminReadWrite") && (
          <Button
            classes={styles.newBtn}
            variant="link-white"
            icon={<NewIcon />}
            label="New"
            onClick={props.onAddLocationClick}
          />
        )}

        {(props.user?.role.includes("AdminReadWrite") ||
          props.user?.role.includes("Customer")) && (
          <Button
            classes={styles.importBtn}
            variant="link-white"
            icon={<UploadIcon isUploading={props.isImporting} />}
            label="Import"
            disabled={props.isImporting}
            onClick={props.onImportClick}
          />
        )}

        <Button
          classes={styles.exportBtn}
          variant="link-white"
          icon={<ExportIcon isExporting={props.isExporting} />}
          label="Export"
          disabled={props.isExporting}
          onClick={props.onExportClick}
        />
      </div>
      <div className={styles.contentWrapper}>{renderLocations()}</div>
    </>
  );
}

export default LocationList;
