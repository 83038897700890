import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import InputField from "../../atoms/InputField";
import Button from "../../atoms/Button";
import styles from "./UserLoginForm.module.css";

export interface UserLoginFormProps {
  username?: string;
  onSubmit(username: string, password: string): void;
}

function UserLoginForm(props: UserLoginFormProps) {
  return (
    <Formik
      initialValues={{ username: props.username ?? "", password: "" }}
      validationSchema={Yup.object({
        username: Yup.string().required(),
        password: Yup.string().required(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values.username, values.password);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form
          className={styles.loginForm}
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(event)
          }
        >
          <InputField
            name="username"
            value={values.username}
            placeholder="Username"
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={
              touched.username !== undefined && errors.username !== undefined
            }
          />
          <InputField
            type="password"
            name="password"
            placeholder="Password"
            value={values.password}
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={
              touched.password !== undefined && errors.password !== undefined
            }
          />
          <Button
            classes={styles.loginFormSubmit}
            size="md"
            variant="outline-green"
            label="Sign In"
            type="submit"
          />
        </form>
      )}
    </Formik>
  );
}

export default UserLoginForm;
