import React, { useCallback, useState } from "react";
import LoadingIndicator from "../components/modules/LoadingIndicator";
import UserLookupForm from "../components/modules/UserLookupForm";
import LocationsView from "../components/modules/LocationsView";
import { UserLookupResponse } from "../models/User";
import ApiService from "../services/ApiService";

function AdminLocationPage() {
  const apiService = useCallback(() => new ApiService(), []);
  const [targetUser, setTargetUser] = useState<UserLookupResponse | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const getUser = async (
    searchField: "email" | "subscriberId" | "accountId" | "phone",
    searchValue: string
  ) => {
    setLoading(true);
    setTargetUser(null);

    // * We only need to perform profile lookups when we don't have a subscription ID
    if (searchField === "accountId" || searchField === "email") {
      const user = await apiService().getUser(searchField, searchValue);
      if (user) {
        setTargetUser(user);
      }
    } else {
      setTargetUser({
        user: {
          username: searchValue,
          subscriberId: searchField === "subscriberId" ? searchValue : "",
          phonenumber: searchField === "phone" ? searchValue : "",
          displayName: "",
          initials: "",
          accountId: "",
          role: "Customer",
        },
        success: true,
        message: "",
      });
    }

    setLoading(false);
  };

  return (
    <>
      <UserLookupForm onSubmit={getUser} error={targetUser?.message} />
      {loading && (
        <section
          className="d-flex flex-column mt-5 justify-content-center ml-auto mr-auto"
          style={{ maxWidth: "90vw" }}
        >
          <LoadingIndicator loading={loading} />
        </section>
      )}
      {targetUser && targetUser.user && (
        <LocationsView currentUser={targetUser.user} />
      )}
    </>
  );
}

export default AdminLocationPage;
