import React from "react";
export default function Business() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="256"
      height="64"
      viewBox="0 0 256 64"
      fill="none"
    >
      <rect width="256" height="64" fill="black" />
      <path
        d="M0 17.0638C0 10.1664 3.84595 7.05817 8.85879 7.05817C12.026 7.05817 14.6337 8.43271 16.1697 11.0208C17.6699 8.44509 20.2776 7.05817 23.4805 7.05817C28.4815 7.05817 32.3393 10.1787 32.3393 17.0638V27.4038C32.3393 28.8651 31.1963 30.0539 29.7912 30.0539C28.3862 30.0539 27.2431 28.8651 27.2431 27.4038V17.0638C27.2431 13.4603 25.4452 11.9867 22.9685 11.9867C20.5276 11.9867 18.7297 13.4975 18.7297 17.0638V27.4038C18.7297 28.8651 17.5866 30.0539 16.1816 30.0539C14.7765 30.0539 13.6335 28.8651 13.6335 27.4038V17.0638C13.6335 13.5098 11.7879 11.9867 9.35888 11.9867C6.88223 11.9867 5.08428 13.4603 5.08428 17.0638V27.4038C5.08428 28.8651 3.94121 30.0539 2.53618 30.0539C1.14307 30.0539 0 28.8651 0 27.4038V17.0638Z"
        fill="#00C900"
      />
      <path
        d="M78.2761 21.3116V2.40235C78.2761 1.07734 79.312 0 80.5861 0H81.0624C82.3364 0 83.3723 1.07734 83.3723 2.40235V7.78906H91.9691C93.2432 7.78906 94.2791 8.8664 94.2791 10.1914C94.2791 11.5164 93.2432 12.5938 91.9691 12.5938H83.3723V21.3116C83.3723 24.2464 84.825 25.4104 86.9206 25.4104C88.5042 25.4104 89.7306 24.1969 90.2188 22.3146C90.5284 21.1382 91.5286 20.3085 92.6955 20.3085C94.3624 20.3085 95.5889 21.9555 95.1721 23.6396C94.1243 27.8004 91.0523 30.3513 86.9206 30.3513C81.7887 30.3513 78.2761 27.4041 78.2761 21.3116Z"
        fill="#00C900"
      />
      <path
        d="M108.305 17.0022C108.305 10.3771 112.068 7.21942 117.593 7.21942C122.189 7.21942 125.535 9.41125 126.535 14.0178C126.868 15.5905 125.737 17.0889 124.189 17.0889H123.927C122.808 17.0889 121.855 16.2716 121.582 15.1447C121.046 13.0148 119.569 12.1603 117.605 12.1603C115.128 12.1603 113.413 13.4482 113.413 17.0146V27.1317C113.413 28.5929 112.27 29.7817 110.865 29.7817C109.46 29.7817 108.317 28.5929 108.317 27.1317V17.0022H108.305Z"
        fill="#00C900"
      />
      <path
        d="M134.548 18.9956C134.548 12.321 139.251 7.42963 145.931 7.42963C152.611 7.42963 157.314 12.321 157.314 18.9956C157.314 25.6702 152.611 30.5615 145.931 30.5615C139.251 30.5615 134.548 25.6702 134.548 18.9956ZM152.134 18.9956C152.134 15.1196 149.658 12.3705 145.931 12.3705C142.204 12.3705 139.68 15.132 139.68 18.9956C139.68 22.8592 142.204 25.6206 145.931 25.6206C149.646 25.633 152.134 22.8715 152.134 18.9956Z"
        fill="#00C900"
      />
      <path
        d="M54.951 7.20734C48.2712 7.20734 43.5679 12.0987 43.5679 18.7733C43.5679 25.4479 48.2712 30.3392 54.951 30.3392C57.8682 30.3392 60.3925 29.4229 62.3095 27.8254C63.2383 27.0577 63.5955 25.6955 63.0001 24.6306C62.2381 23.2808 60.4282 23.0827 59.4399 24.0362C58.3802 25.0516 56.8799 25.5345 54.951 25.5345C52.3315 25.5345 50.2001 24.2343 49.2476 21.77C49.0213 21.1756 48.9023 20.5441 48.8308 19.9002L48.807 19.702C48.6879 18.6247 48.7951 17.6216 48.9737 16.6681L49.0451 16.4081C49.8548 13.5104 52.0814 11.9997 54.951 11.9997C57.7372 11.9997 60.0472 13.4733 60.8569 16.4081H53.9508C52.8554 16.4081 51.9504 17.3121 51.9147 18.4513C51.879 19.6401 52.7958 20.6308 53.9508 20.6308H64.4528C65.453 20.6308 66.2507 19.7887 66.2507 18.7485C66.2388 12.0987 61.6189 7.20734 54.951 7.20734Z"
        fill="#00C900"
      />
      <path
        d="M234.484 21.3116V2.40235C234.484 1.07734 235.52 0 236.794 0H237.27C238.544 0 239.58 1.07734 239.58 2.40235V7.78906H248.177C249.451 7.78906 250.487 8.8664 250.487 10.1914C250.487 11.5164 249.451 12.5938 248.177 12.5938H239.58V21.3116C239.58 24.2464 241.033 25.4104 243.128 25.4104C244.712 25.4104 245.938 24.1969 246.427 22.3146C246.736 21.1382 247.736 20.3085 248.903 20.3085C250.57 20.3085 251.797 21.9555 251.38 23.6396C250.332 27.8004 247.26 30.3513 243.128 30.3513C237.984 30.3513 234.484 27.4041 234.484 21.3116Z"
        fill="#9645FD"
      />
      <path
        d="M169.222 18.3275C169.222 10.9842 173.413 7.20734 179.665 7.20734C185.916 7.20734 190.107 10.9471 190.107 18.3275V27.1196C190.107 28.5808 188.964 29.7696 187.559 29.7696C186.154 29.7696 185.011 28.5808 185.011 27.1196V18.3275C185.011 14.142 182.868 12.1483 179.665 12.1483C176.462 12.1483 174.318 14.1543 174.318 18.3275V27.1196C174.318 28.5808 173.175 29.7696 171.77 29.7696C170.365 29.7696 169.222 28.5808 169.222 27.1196V18.3275V18.3275Z"
        fill="#9645FD"
      />
      <path
        d="M212.599 7.20734C205.919 7.20734 201.216 12.0987 201.216 18.7733C201.216 25.4479 205.919 30.3392 212.599 30.3392C215.516 30.3392 218.04 29.4229 219.957 27.8254C220.886 27.0577 221.243 25.6955 220.648 24.6306C219.886 23.2808 218.076 23.0827 217.088 24.0362C216.028 25.0516 214.528 25.5345 212.599 25.5345C209.979 25.5345 207.848 24.2343 206.895 21.77C206.669 21.1756 206.55 20.5441 206.479 19.9002L206.455 19.702C206.336 18.6247 206.443 17.6216 206.622 16.6681L206.693 16.4081C207.503 13.5104 209.729 11.9997 212.599 11.9997C215.385 11.9997 217.695 13.4733 218.505 16.4081H211.599C210.503 16.4081 209.598 17.3121 209.563 18.4513C209.527 19.6401 210.444 20.6308 211.599 20.6308H222.101C223.101 20.6308 223.899 19.7887 223.899 18.7485C223.887 12.0987 219.267 7.20734 212.599 7.20734Z"
        fill="#9645FD"
      />
      <path
        d="M250.641 0.742968H249.867V0.0618896H252.13V0.742968H251.356V3.02149H250.653V0.742968H250.641Z"
        fill="#9645FD"
      />
      <path
        d="M252.38 1.15164C252.38 0.359114 252.821 0 253.404 0C253.75 0 254.023 0.136216 254.19 0.408647C254.357 0.136216 254.631 0 254.976 0C255.548 0 256 0.359114 256 1.15164V3.02151H255.298V1.15164C255.298 0.804911 255.131 0.681079 254.928 0.681079C254.714 0.681079 254.559 0.804911 254.559 1.15164V3.02151H253.857V1.15164C253.857 0.804911 253.69 0.681079 253.488 0.681079C253.273 0.681079 253.119 0.804911 253.119 1.15164V3.02151H252.416V1.15164H252.38Z"
        fill="#9645FD"
      />
      <path
        d="M108.806 54.2271V39.8873H112.378V48.8899C113.425 47.1687 115.2 46.2399 117.331 46.2399C120.939 46.2399 124.725 48.9271 124.725 54.2024C124.725 59.1061 121.415 62.4867 116.7 62.4867C112.044 62.4744 108.806 59.2176 108.806 54.2271ZM121.117 54.351C121.117 51.6266 119.343 49.6949 116.736 49.6949C114.116 49.6949 112.378 51.6266 112.378 54.351C112.378 57.0753 114.116 59.0071 116.736 59.0071C119.343 59.0071 121.117 57.0629 121.117 54.351Z"
        fill="#9645FD"
      />
      <path
        d="M130.465 54.6606V46.6362H134.037V54.6606C134.037 57.5954 135.537 59.0071 137.787 59.0071C140.038 59.0071 141.538 57.5954 141.538 54.6606V46.6362H145.11V54.6606C145.11 59.812 142.169 62.4744 137.775 62.4744C133.406 62.4744 130.465 59.8492 130.465 54.6606Z"
        fill="#9645FD"
      />
      <path
        d="M150.896 57.5071H154.504C154.54 58.5349 155.409 59.2284 157.148 59.2284C158.981 59.2284 159.767 58.4482 159.767 57.5071C159.767 56.3183 158.445 56.1697 156.85 55.9468C154.564 55.6372 151.206 55.2286 151.206 51.4517C151.206 48.294 153.516 46.2383 157.1 46.2383C160.732 46.2383 162.958 48.3683 163.054 51.0802H159.505C159.41 50.1391 158.576 49.4828 157.16 49.4828C155.6 49.4828 154.814 50.2629 154.814 51.1669C154.814 52.3557 156.171 52.5414 157.755 52.7643C159.743 53.0491 163.375 53.4578 163.375 57.2966C163.375 60.4172 160.946 62.4852 157.16 62.4852C153.361 62.4728 150.98 60.38 150.896 57.5071Z"
        fill="#9645FD"
      />
      <path
        d="M169.078 42.128C169.078 40.6915 170.067 39.6266 171.448 39.6266C172.829 39.6266 173.817 40.6915 173.817 42.128C173.817 43.5645 172.829 44.5923 171.448 44.5923C170.067 44.5923 169.078 43.5645 169.078 42.128ZM169.65 46.5984H173.222V62.0279H169.65V46.5984Z"
        fill="#9645FD"
      />
      <path
        d="M180.069 54.0411C180.069 48.8897 183.01 46.2273 187.404 46.2273C191.786 46.2273 194.739 48.8525 194.739 54.0411V62.0655H191.167V54.0411C191.167 51.1063 189.666 49.6946 187.416 49.6946C185.166 49.6946 183.665 51.1063 183.665 54.0411V62.0655H180.093V54.0411H180.069Z"
        fill="#9645FD"
      />
      <path
        d="M200.466 54.3507C200.466 49.6698 203.776 46.2273 208.455 46.2273C213.135 46.2273 216.385 49.6698 216.385 54.3507V55.6633H204.216C204.693 57.9171 206.348 59.1059 208.455 59.1059C210.051 59.1059 211.218 58.6353 211.968 57.6323H215.778C214.671 60.5671 211.968 62.4741 208.443 62.4741C203.764 62.4741 200.466 59.0316 200.466 54.3507ZM212.599 52.6913C212.027 50.6233 210.408 49.5955 208.455 49.5955C206.443 49.5955 204.883 50.6605 204.312 52.6913H212.599Z"
        fill="#9645FD"
      />
      <path
        d="M221.553 57.5071H225.16C225.196 58.5349 226.065 59.2284 227.804 59.2284C229.637 59.2284 230.423 58.4482 230.423 57.5071C230.423 56.3183 229.102 56.1697 227.506 55.9468C225.22 55.6372 221.862 55.2286 221.862 51.4517C221.862 48.294 224.172 46.2383 227.756 46.2383C231.388 46.2383 233.614 48.3683 233.71 51.0802H230.161C230.066 50.1391 229.233 49.4828 227.816 49.4828C226.256 49.4828 225.47 50.2629 225.47 51.1669C225.47 52.3557 226.827 52.5414 228.411 52.7643C230.399 53.0491 234.031 53.4578 234.031 57.2966C234.031 60.4172 231.602 62.4852 227.816 62.4852C224.017 62.4728 221.648 60.38 221.553 57.5071Z"
        fill="#9645FD"
      />
      <path
        d="M239.223 57.5071H242.831C242.867 58.5349 243.736 59.2284 245.474 59.2284C247.308 59.2284 248.094 58.4482 248.094 57.5071C248.094 56.3183 246.772 56.1697 245.177 55.9468C242.89 55.6372 239.533 55.2286 239.533 51.4517C239.533 48.294 241.843 46.2383 245.427 46.2383C249.058 46.2383 251.285 48.3683 251.38 51.0802H247.832C247.737 50.1391 246.903 49.4828 245.486 49.4828C243.926 49.4828 243.141 50.2629 243.141 51.1669C243.141 52.3557 244.498 52.5414 246.082 52.7643C248.07 53.0491 251.702 53.4578 251.702 57.2966C251.702 60.4172 249.273 62.4852 245.486 62.4852C241.688 62.4728 239.306 60.38 239.223 57.5071Z"
        fill="#9645FD"
      />
    </svg>
  );
}
