import React from "react";

export default function WellnessIncentives() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_15541_8467)">
        <path
          d="M16.3391 11.79C16.3391 6.98654 12.0091 3.077 12.0091 3.077C12.0091 3.077 7.66992 6.98654 7.66992 11.79C7.66992 16.5934 12.0091 20.503 12.0091 20.503C12.0091 20.503 16.3391 16.6042 16.3391 11.79Z"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.3394 11.5638C18.2174 10.9545 20.1989 10.9285 22.0881 11.4884C22.0881 11.4884 22.0881 16.9704 19.3243 19.4475C16.5605 21.9246 12.0278 20.5245 12.0278 20.5245"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.67013 11.5638C5.79212 10.9545 3.81062 10.9285 1.92139 11.4884C1.92139 11.4884 1.92139 16.9704 4.68521 19.4475C7.44903 21.9246 11.9817 20.5245 11.9817 20.5245"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_15541_8467">
          <rect
            width="22"
            height="20"
            fill="white"
            transform="translate(1 2)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
