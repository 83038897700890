import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import Button from "../../atoms/Button";
import InputField from "../../atoms/InputField";

export interface SearchBoxProps {
  className?: string;
  onChange?: (value: string) => void;
  onClick?: (value: string) => void;
}

function SearchBox(props: SearchBoxProps) {
  const [searchTerm, setSearchTerm] = useState("");

  const onSearchChange = (value: string) => {
    setSearchTerm(value);

    if (props.onChange) {
      props.onChange(value);
    }
  };

  const onSearchClick = (value: string) => {
    if (props.onClick) {
      props.onClick(value);
    } else {
      setSearchTerm("");
      if (props.onChange) props.onChange("");
    }
  };

  const renderButton = () => {
    const icon =
      props.onChange && props.onClick === undefined && searchTerm !== ""
        ? faTimes
        : faSearch;
    return (
      <Button
        variant="link-white"
        icon={<FontAwesomeIcon icon={icon} />}
        type="button"
        onClick={() => onSearchClick(searchTerm)}
      />
    );
  };

  return (
    <>
      <InputField
        placeholder="Search Phone Numbers"
        value={searchTerm}
        onChange={(e) => onSearchChange(e.target.value)}
        onFocus={(e: any) => e.target.select()}
        onKeyDown={(e: any) => {
          if (e.key === "Enter") {
            onSearchClick(searchTerm);
          }
        }}
        right={renderButton()}
      />
    </>
  );
}

export default SearchBox;
