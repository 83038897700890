import React from "react";

export default function TwitterSolid() {
  return (
    <svg
      width="24"
      height="20"
      viewBox="0 0 24 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.55016 19.75C16.6045 19.75 21.5583 12.2468 21.5583 5.74192C21.5583 5.53098 21.5536 5.31536 21.5442 5.10442C22.5079 4.40752 23.3395 3.54432 24 2.55536C23.1025 2.95466 22.1496 3.21544 21.1739 3.3288C22.2013 2.71297 22.9705 1.74553 23.3391 0.605828C22.3726 1.17862 21.3156 1.58267 20.2134 1.80067C19.4708 1.01162 18.489 0.489177 17.4197 0.314112C16.3504 0.139047 15.2532 0.321111 14.2977 0.832157C13.3423 1.3432 12.5818 2.15477 12.1338 3.14137C11.6859 4.12798 11.5754 5.23468 11.8195 6.29036C9.86249 6.19215 7.94794 5.68377 6.19998 4.79816C4.45203 3.91255 2.90969 2.6695 1.67297 1.14958C1.0444 2.2333 0.852057 3.51571 1.13503 4.73615C1.418 5.9566 2.15506 7.02351 3.19641 7.72005C2.41463 7.69523 1.64998 7.48474 0.965625 7.10598V7.16692C0.964925 8.30421 1.3581 9.40665 2.07831 10.2868C2.79852 11.167 3.80132 11.7706 4.91625 11.995C4.19206 12.1932 3.43198 12.2221 2.69484 12.0794C3.00945 13.0575 3.62157 13.913 4.44577 14.5264C5.26997 15.1398 6.26512 15.4807 7.29234 15.5013C5.54842 16.8712 3.39417 17.6142 1.17656 17.6107C0.783287 17.6101 0.390399 17.586 0 17.5385C2.25286 18.9838 4.87353 19.7514 7.55016 19.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
