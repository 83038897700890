import React, { useCallback, useMemo } from "react";
import ModalWrapper from "../components/modules/ModalWrapper";
import useModal from "./useModal";
import LocationForm from "../components/modules/LocationForm";
import TE911Location from "../models/TE911Location";
import ApiResponseAlert from "../components/modules/ApiResponseAlert";
import { ApiResponse } from "../models/ApiResponses";

export interface LocationDialogProps {
  apiResponse?: ApiResponse | null;
  location?: TE911Location;
  isNew?: boolean;
  submitting?: boolean;
  validatedAddress?: boolean;
  onSubmit: (location: TE911Location) => Promise<boolean>;
  onCancel: () => void;
}

function useLocationDialog() {
  const { show, hide, RenderModal: RenderLocationForm } = useModal(); // control methods for showing the location edit form

  const render = useCallback(
    (props: LocationDialogProps) => (
      <RenderLocationForm>
        <ModalWrapper
          title={`${
            props.location && props.location.phoneNumberFormatted.length > 0
              ? props.location.phoneNumberFormatted
              : "New Location"
          }`}
          size="lg"
          onClose={props.onCancel}
        >
          <>
            <ApiResponseAlert isInline apiResponse={props.apiResponse} />
            <LocationForm
              isDialog
              isNew={props.isNew}
              location={props.location}
              validatedAddress={props.validatedAddress}
              submitting={props.submitting ?? false}
              onSubmit={(loc) => props.onSubmit(loc)}
              onCancel={props.onCancel}
            />
          </>
        </ModalWrapper>
      </RenderLocationForm>
    ),
    [RenderLocationForm]
  );

  return useMemo(
    () => ({
      show,
      hide,
      render,
    }),
    [hide, render, show]
  );
}

export default useLocationDialog;
