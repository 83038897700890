import React from "react";
import clsx from "clsx";
import { v4 as generateUuid } from "uuid";

import styles from "./InputField.module.css";

/**
 * Modified from commercial-web copy
 * Added accept attribute.
 * Added onKeyDown event.
 * Updated wrapper CSS to not hide overflow.
 * Updated CSS so that hint displays under the input - otherwise it looks a bit unnatural when there are left and right adornments.
 * Added maxLength prop.
 * Added autoComplete prop to allow disabling autoComplete on input.
 */

export type Props = {
  accept?: string;
  hasLightStyles?: boolean;
  disabled?: boolean;
  /** Text that appears below the input */
  hint?: string;
  inputRef?: React.Ref<HTMLInputElement>;
  /** If true, hint text and outline changes to red */
  invalid?: boolean;
  label?: string;
  /** Left affix (eg. Button, Dropdown, Icon) */
  left?: React.ReactNode;
  /** Right affix (eg. Button, Dropdown, Icon) */
  right?: React.ReactNode;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onFocus?: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  /** Type attribute of native input element */
  type?: React.HTMLInputTypeAttribute;
  value?: string;
  classes?: string;
  inputClasses?: string;
  /** Name used to identify form field for validation */
  name?: string;
  /** Pattern used to drive keyboards */
  pattern?: string;
  /** Flag to indicate if input is read-only */
  readOnly?: boolean;
  /** If true, hide the error message container */
  hideErrorMessage?: boolean;
  maxLength?: number;
  autoComplete?: string | undefined;
};

export function InputField({
  accept,
  hasLightStyles,
  classes,
  inputClasses,
  disabled,
  hint,
  invalid,
  label,
  left,
  right,
  onBlur,
  onChange,
  onFocus,
  onKeyDown,
  placeholder,
  inputRef,
  type,
  value,
  name,
  pattern,
  readOnly,
  hideErrorMessage,
  maxLength,
  autoComplete,
}: Props) {
  const className = clsx(classes, styles.inputField, {
    [styles.disabled]: disabled,
    [styles.invalid]: invalid,
    [styles.hasLightStyles]: hasLightStyles,
  });

  const id = generateUuid();

  // Function to block entry of spaces for email input types
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (type === "email" && e.key === " ") {
      e.preventDefault();
    }

    if (onKeyDown) onKeyDown(e);
  };

  return (
    <div className={className}>
      <span className={styles.label}>{label}</span>
      <div className={styles.wrapper}>
        {left}
        <input
          id={`inputfield-${id}`}
          accept={accept}
          className={clsx(styles.input, inputClasses)}
          disabled={disabled}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          onKeyDown={handleKeyDown}
          ref={inputRef}
          type={type}
          value={value}
          name={name}
          pattern={pattern}
          readOnly={readOnly}
          maxLength={maxLength}
          autoComplete={autoComplete}
        />
        <label
          htmlFor={`inputfield-${id}`}
          className={clsx("absolute pointer-events-none", {
            hidden: Boolean(value),
            "left-[2.85rem]": Boolean(left),
          })}
        >
          {placeholder}
        </label>
        <span className={styles.right}>{right}</span>
      </div>
      {!hideErrorMessage && <span className={styles.hint}>{hint}</span>}
    </div>
  );
}
