import React, { useCallback, useContext, useEffect, useState } from "react";
import { saveAs } from "file-saver";
import ApiService from "../../../services/ApiService";
import TE911Location from "../../../models/TE911Location";
import LocationList from "../LocationList";
import LocationForm from "../LocationForm";
import LoadingIndicator from "../LoadingIndicator";
import useFileBrowseDialog from "../../../hooks/useFileDialog";
import useLocationDialog from "../../../hooks/useLocationDialog";
import { User } from "../../../models/User";
import AlertContext from "../../../contexts/AlertContext";
import NoLocations from "../NoLocations";
import clsx from "clsx";
import DialogOpenContext, {
  DialogOpenState,
} from "../../../contexts/DialogOpenContext";

interface LocationsViewProps {
  currentUser: User;
}

function LocationsView({ currentUser }: LocationsViewProps) {
  const apiService = useCallback(() => new ApiService(), []); // creates the api service for use in this component
  const authUser = apiService().getCurrentUser();

  const [locations, setLocations] = useState<TE911Location[]>([]); // the list of phone locations that is return from the api

  const [isNew, setIsNew] = useState(false); // indicates if we're adding a new location or updating an existing one
  const [validatedAddress, setValidatedAddress] = useState(false); // indicates if the location returned from the api has had its address validated and is different from user input

  const [search, setSearch] = useState(""); // search term to filter locations by

  const [isLocationsLoaded, setIsLocationsLoaded] = useState(false);
  const [loading, setLoading] = useState(false); // toggles the loading indicator
  const [exporting, setExporting] = useState(false); // toggles the export button spinning icon
  const [updating, setUpdating] = useState(false); // toggles the location form's save button spinning icon

  const [importing, setImporting] = useState(false); // toggles the file save form's import button spinning icon
  const [selectedFile, setSelectedFile] = useState<File | undefined>(); // the file selected by the user in the file browse modal
  const [selectedLocation, setSelectedLocation] = useState<
    TE911Location | undefined
  >(); // the selected location for editing in the location dialog
  const { isDialogOpen, setIsDialogOpen } = useContext<DialogOpenState>(
    DialogOpenContext
  );

  const { alert, setAlert } = useContext(AlertContext);
  const {
    show: fileBrowseShow,
    hide: fileBrowseHide,
    render: renderFileBrowse,
  } = useFileBrowseDialog();

  const {
    show: locationDialogShow,
    hide: locationDialogHide,
    render: renderLocationDialog,
  } = useLocationDialog();

  const showLocationDialog = () => {
    setIsDialogOpen(true);
    locationDialogShow();
  };

  const hideLocationDialog = () => {
    setIsDialogOpen(false);
    locationDialogHide();
  };

  const showFileBrowse = () => {
    setIsDialogOpen(true);
    fileBrowseShow();
  };

  const hideFileBrowse = () => {
    setIsDialogOpen(false);
    fileBrowseHide();
  };

  const getNewLocation = useCallback(
    (phone?: string): TE911Location => {
      return {
        subId: currentUser.subscriberId ?? "",
        phoneNumber: phone ?? "",
        phoneNumberFormatted: phone
          ? apiService().formatPhoneNumber(phone)
          : "",
        address1: "",
        address2: "",
        additionalInfo: "",
        city: "",
        state: "",
        zip: "",
        bypassAddressValidation: "",
      };
    },
    [apiService, currentUser.subscriberId]
  );

  const openLocationDialog = () => {
    setAlert(null);
    showLocationDialog();
  };

  const closeLocationDialog = () => {
    hideLocationDialog();
  };

  const handleLocationUpdate = async (location: TE911Location) => {
    setUpdating(true);
    setAlert(null);
    setSelectedLocation({ ...location });
    const response = await apiService().updateLocation(location);
    if (response.requestSucceeded && response.location && locations) {
      const isAddressChanged =
        location.address1.toLowerCase() !==
          response.location.address1.toLowerCase() ||
        location.address2.toLowerCase() !==
          response.location.address2.toLowerCase();

      // location was updated to what user entered, update the list and show the response
      const pos = locations.findIndex(
        (l) => l.phoneNumber === response.location?.phoneNumber
      );

      if (pos !== undefined) {
        locations.splice(pos, 1, response.location);
        setLocations([...locations]);
      }

      setValidatedAddress(isAddressChanged);
      if (response.requestSucceeded && !isAddressChanged && isDialogOpen)
        closeLocationDialog();

      setSelectedLocation({ ...response.location });
    }

    setAlert(response);
    setUpdating(false);
    return response.requestSucceeded;
  };

  useEffect(() => {
    if (locations.length === 0) {
      setLoading(true);
      apiService()
        .getLocations(currentUser?.subscriberId ?? "", currentUser?.phonenumber)
        .then((response) => {
          if (response && response.status === 200) {
            if (
              response &&
              response.locations &&
              response.locations.length > 0
            ) {
              setLocations(response.locations);
            } else if (
              authUser?.role === "AdminReadWrite" &&
              currentUser?.phonenumber
            ) {
              setLocations([getNewLocation(currentUser.phonenumber)]);
            }

            setIsLocationsLoaded(true);
            setAlert(null);
          } else {
            setIsLocationsLoaded(false);
            setAlert(response);
          }

          setLoading(false);
        });
    }
  }, [
    locations,
    apiService,
    getNewLocation,
    currentUser,
    setAlert,
    authUser?.role,
  ]);

  const filteredLocations = useCallback(() => {
    if (locations !== undefined && locations.length > 0) {
      return locations.filter(
        (l) => search === "" || l.phoneNumber.includes(search)
      );
    } else {
      return [];
    }
  }, [search, locations]);

  const renderLocations = () => {
    // ! Don't display locations if there was an error fetching them.
    if (alert && alert.message === "Failed to fetch locations.") return null;

    // ! If we don't receive locations, only render list or form for admin users.
    const haveLocation =
      locations.length > 0 && locations[0].phoneNumberFormatted !== "";
    if (
      !haveLocation &&
      authUser &&
      authUser.role.localeCompare("Customer") === 0
    ) {
      return <NoLocations />;
    }

    if (currentUser && !currentUser.phonenumber) {
      return (
        <>
          <LocationList
            locations={filteredLocations()}
            isExporting={exporting}
            isImporting={importing}
            user={authUser}
            onSearchChange={(search) => setSearch(search)}
            onAddLocationClick={() => {
              setSelectedLocation(getNewLocation());
              setIsNew(true);
              openLocationDialog();
            }}
            onExportClick={async () => {
              setExporting(true);
              const response = await apiService().exportLocations(
                currentUser.subscriberId
              );
              if (response?.requestSucceeded && response.fileContents) {
                saveAs(response.fileContents, response.fileDownloadName);
              }
              setExporting(false);
            }}
            onImportClick={() => {
              showFileBrowse();
            }}
            onLocationSelected={(location) => {
              setSelectedLocation({ ...location });
              setIsNew(false);
              openLocationDialog();
            }}
          />
        </>
      );
    } else {
      return (
        <div className="grid grid-cols-12 items-center">
          <div className="col-start-2 col-span-10 lg:col-start-4 lg:col-span-6 bg-gray-900 border border-gray-900 rounded-md drop-shadow-md">
            <LocationForm
              showResetButton
              location={locations[0]}
              validatedAddress={validatedAddress}
              submitting={updating}
              onSubmit={(location) => handleLocationUpdate(location)}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <section
        className={clsx("mt-5 flex flex-col", {
          "h-[65vh]": isDialogOpen,
          "overflow-hidden": isDialogOpen,
        })}
      >
        {renderFileBrowse({
          file: selectedFile,
          importing,
          onImport: async (file) => {
            setSelectedFile(file);
            setImporting(true);
            const response = await apiService().importLocations(
              currentUser.subscriberId ?? "",
              file
            );
            setLocations(response.locations);
            hideFileBrowse();
            setImporting(false);
            setSelectedFile(undefined);
            setAlert(response);
          },
          onCancel: () => {
            setSelectedFile(undefined);
            hideFileBrowse();
          },
        })}

        {renderLocationDialog({
          apiResponse: alert,
          isNew: isNew,
          location: selectedLocation,
          submitting: updating,
          validatedAddress,
          onSubmit: (update) => handleLocationUpdate(update),
          onCancel: () => {
            setUpdating(false);
            setValidatedAddress(false);
            closeLocationDialog();
          },
        })}

        <LoadingIndicator loading={loading} />
        <div className="flex flex-col items-center w-full">
          {isLocationsLoaded && renderLocations()}
        </div>
      </section>
    </>
  );
}

export default LocationsView;
