import clsx from "clsx";
import React from "react";
import { ApiResponse } from "../../../models/ApiResponses";
import Button from "../../atoms/Button";
import AlertIcon from "../AlertIcon";
import CancelIcon from "../CancelIcon";
import styles from "./ApiResponseAlert.module.css";

interface ApiResponseAlertProps {
  /** The response object returned from the API. */
  apiResponse?: ApiResponse | null;
  /** If the alert is inline, it will not try to be full width. */
  isInline?: boolean;
  onClose?: () => void;
}

function ApiResponseAlert({
  apiResponse,
  isInline,
  onClose,
}: ApiResponseAlertProps) {
  const success = apiResponse?.requestSucceeded ?? false;
  const hidden =
    apiResponse === null ||
    apiResponse === undefined ||
    apiResponse.message === "";

  if (hidden) return null;

  // color={success ? "green" : "purple"}

  return (
    <div
      className={clsx(
        styles.apiResponseAlert,
        success && styles.apiResponseAlertSuccess,
        !success && styles.apiResponseAlertError,
        {
          "justify-between": onClose,
          "justify-center": !onClose,
          "border-2 border-green-1 text-green-1 bg-gray-900":
            success && isInline,
          "bg-green-1 text-white": success && !isInline,
          "bg-purple-1 text-white": !success && !isInline,
          "border-2 border-purple-1 text-purple-1": !success && isInline,
          "rounded-lg text-center": isInline,
          "p-3": !isInline,
        }
      )}
    >
      <div className="flex items-center gap-2">
        {!success && <AlertIcon />}
        <div>{apiResponse.message}</div>
      </div>
      {onClose && (
        <div>
          <Button
            variant="link-white"
            icon={<CancelIcon />}
            onClick={onClose}
          />
        </div>
      )}
    </div>
  );
}

export default ApiResponseAlert;
