import { useCallback, useEffect, useState } from "react";
// import { TailwindThemeValue } from "tailwindcss/tailwind-config";

export type Breakpoint = "sm" | "md" | "desktopNav" | "lg" | "xl" | "2xl";

// function tailwindThemeValueHasKey(
//   key: string | number,
//   obj: TailwindThemeValue | undefined
// ): obj is Record<string, string> {
//   return !!obj && typeof obj !== "function" && key in obj;
// }

function getParsedBreakpoints(): Record<string, string> {
  return { sm: "376px", md: "769px", desktopNav: "1001px", lg: "1281px" };
}

const parsedBreakpoints = getParsedBreakpoints();

export default function useMediaQuery(breakpoint: Breakpoint) {
  const [matchesBreakpoint, setMatchesBreakpoint] = useState<boolean>(true);

  useEffect(() => {
    setMatchesBreakpoint(
      window.matchMedia(`(min-width: ${parsedBreakpoints[breakpoint]})`).matches
    );
  }, [setMatchesBreakpoint, breakpoint]);

  const onResize = useCallback(() => {
    if (typeof window !== "undefined") {
      setMatchesBreakpoint(
        window.matchMedia(`(min-width: ${parsedBreakpoints[breakpoint]})`)
          .matches
      );
    } else {
      setMatchesBreakpoint(true);
    }
  }, [setMatchesBreakpoint, breakpoint]);

  useEffect(() => {
    window.addEventListener("resize", onResize);

    return () => window.removeEventListener("resize", onResize);
  }, [onResize]);

  return matchesBreakpoint;
}
