import { User } from "../models/User";

class LocalStorageService {
  getUser(): User | null {
    const json = localStorage.getItem("user");
    if (json) {
      return JSON.parse(json);
    }

    return null;
  }

  setUser(user: User) {
    localStorage.setItem("user", JSON.stringify(user));
    window.dispatchEvent(new Event("storage"));
  }

  getAccessToken() {
    return localStorage.getItem("access_token");
  }

  setAccessToken(val: string) {
    localStorage.setItem("access_token", val);
    window.dispatchEvent(new Event("storage"));
  }

  clear() {
    localStorage.clear();
    window.dispatchEvent(new Event("storage"));
  }
}

export default LocalStorageService;
