import { PhoneNumberUtil } from "google-libphonenumber";

export default function validatePhoneNumber(value: any): boolean {
  try {
    const util = new PhoneNumberUtil();
    const number = util.parseAndKeepRawInput(value, "US");
    return util.isValidNumberForRegion(number, "US");
  } catch {
    return false;
  }
}
