import React from "react";
import styles from "./Button.module.css";
import clsx from "clsx";

/**
 * Modified from commercial-web copy.
 * Removed link button logic.
 * Added type property.
 * Added mouse down event.
 */

export const variants = [
  "solid-yellow",
  "solid-teal",
  "solid-white",
  "solid-green",
  "outline-white",
  "outline-green",
  "outline-yellow",
  "link-green-caps",
  "link-white-caps",
  "link-green",
  "link-white",
] as const;
export type StyleVariant = typeof variants[number];

export const sizes = ["sm", "md", "lg", "xl"] as const;
export type SizeVariant = typeof sizes[number];

export const iconAlignments = ["left", "right"] as const;
export type IconAlignment = typeof iconAlignments[number];

export const textAlignments = ["left", "center", "right"] as const;
export type TextAlignment = typeof textAlignments[number];

export type BaseProps = {
  /** Links cannot be disabled */
  disabled?: boolean;
  icon?: React.ReactNode;
  iconAlignment?: IconAlignment;
  textAlignment?: TextAlignment;
  justifyBetweenIconAndText?: boolean;
  label?: string;
  size?: SizeVariant;
  variant?: StyleVariant;
  classes?: string;
  type?: "button" | "submit" | "reset" | undefined;
};

export type OnClickButtonProps = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  onMouseDown?: React.MouseEventHandler<HTMLButtonElement>;
};
export type ButtonOnly = BaseProps & OnClickButtonProps;

export type Props = ButtonOnly;

export function Button({
  justifyBetweenIconAndText = false,
  disabled = false,
  icon,
  iconAlignment = "left",
  textAlignment = "center",
  label,
  size = "md",
  variant = "solid-yellow",
  classes = "",
  type = "button",
  ...rest
}: Props): JSX.Element {
  const className = clsx(
    styles.button,
    styles[size],
    styles[`${variant}-variant`],
    {
      "flex-row-reverse": iconAlignment === "right",
      [styles["no-label"]]: !label,
      "justify-start":
        iconAlignment === "right"
          ? textAlignment === "right"
          : textAlignment === "left",
      "justify-center": textAlignment === "center",
      "justify-end":
        iconAlignment === "right"
          ? textAlignment === "left"
          : textAlignment === "right",
      "justify-between": justifyBetweenIconAndText,
    },
    classes
  );

  return (
    <button
      type={type}
      className={className}
      disabled={disabled}
      onClick={rest.onClick}
      onMouseDown={rest.onMouseDown}
    >
      {icon}
      {label}
    </button>
  );
}
