import { createContext, Dispatch, SetStateAction } from "react";
import { ApiResponse } from "../models/ApiResponses";

interface AlertState {
  alert: ApiResponse | null;
  setAlert: Dispatch<SetStateAction<ApiResponse | null>>;
}

const AlertContext = createContext<AlertState>({
  alert: null,
  setAlert: () => {},
});

export default AlertContext;
