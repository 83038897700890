import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputField } from "../../atoms/InputField";
import Button from "../../atoms/Button";
import styles from "./EmailLoginForm.module.css";

export interface EmailLoginFormProps {
  email?: string;
  onSubmit(email: string, password: string): void;
}

function EmailLoginForm(props: EmailLoginFormProps) {
  return (
    <Formik
      initialValues={{ email: props.email ?? "", password: "" }}
      validationSchema={Yup.object({
        email: Yup.string().email().required(),
        password: Yup.string().required(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values.email, values.password);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
        setFieldTouched,
      }) => (
        <form
          className={styles.loginForm}
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(event)
          }
        >
          <InputField
            name="email"
            value={values.email}
            placeholder="Email"
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={touched.email !== undefined && errors.email !== undefined}
          />
          <InputField
            type="password"
            name="password"
            value={values.password}
            placeholder="Password"
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={
              touched.password !== undefined && errors.password !== undefined
            }
          />
          <Button
            classes={styles.loginFormSubmit}
            size="md"
            variant="outline-green"
            label="Sign In"
            type="submit"
          />
        </form>
      )}
    </Formik>
  );
}

export default EmailLoginForm;
