import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import clsx from "clsx";
import styles from "./LoadingIndicator.module.css";

export interface LoadingIndicatorProps {
  className?: string;
  loading: boolean;
}

function LoadingIndicator(props: LoadingIndicatorProps) {
  return (
    <>
      {props.loading && (
        <div
          className={clsx(styles.loadingIndicator, props.className)}
          data-testid="loading-indicator"
        >
          <FontAwesomeIcon
            className="text-primary"
            size="3x"
            spin
            icon={faCircleNotch}
          />
        </div>
      )}
    </>
  );
}

export default LoadingIndicator;
