import { createContext, Dispatch, SetStateAction } from "react";

export interface DialogOpenState {
  isDialogOpen: boolean;
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>;
}

const DialogOpenContext = createContext<DialogOpenState>({
  isDialogOpen: false,
  setIsDialogOpen: () => {},
});

export default DialogOpenContext;
