import React from "react";
import clsx from "clsx";
import { MouseEvent, useState } from "react";

/**
 * This component has been changed from the commercial web version.
 * Added variant option to allow consumers to control button display.
 * Changed styles to allow button to shrink while maintaining min width on menu.
 */

import Button from "../Button";
import Icon from "../Icon";

export type Option = {
  label: string;
  value: string;
};

export type Props = {
  variant?:
    | "solid-yellow"
    | "solid-teal"
    | "solid-white"
    | "solid-green"
    | "outline-white"
    | "outline-green"
    | "outline-yellow"
    | "link-green-caps"
    | "link-white-caps"
    | "link-green"
    | "link-white"
    | undefined;
  value: Option | undefined;
  options: Option[];
  onChange: (
    event: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>,
    value: Option
  ) => void;
  fullWidth?: boolean;
  placeholder?: string;
};

export default function Dropdown({
  variant,
  options,
  onChange,
  value,
  fullWidth,
  placeholder,
}: Props) {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <div className="relative text-white">
      <Button
        classes={clsx({ "w-full": fullWidth })}
        textAlignment="left"
        label={value?.label ?? placeholder}
        icon={
          menuIsOpen ? <Icon name="chevron-up" /> : <Icon name="chevron-down" />
        }
        iconAlignment="right"
        variant={variant}
        justifyBetweenIconAndText
        onClick={() => setMenuIsOpen(true)}
      />
      {menuIsOpen && (
        <>
          <div
            className="w-screen h-screen fixed top-0 left-0 z-10"
            onClick={() => setMenuIsOpen(false)}
          />
          <div className="absolute top-full left-0 z-10 w-full min-w-[10rem] bg-gray-900 rounded-lg flex flex-col">
            {options.map((option) => (
              <button
                key={`dropdown-${option.value}`}
                className={clsx(
                  "flex items-center justify-between p-4 w-full hover:bg-gray-800 text-left last:rounded-b-lg first:rounded-t-lg",
                  {
                    "bg-gray-800": value && option.value === value.value,
                  }
                )}
                onClick={(e) => {
                  onChange(e, option);
                  setMenuIsOpen(false);
                }}
              >
                <span className="overflow-hidden text-ellipsis">
                  {option.label}
                </span>
                {value && option.value === value.value && (
                  <Icon className="text-green-1" name="check" />
                )}
              </button>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
