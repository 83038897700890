import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import validatePhoneNumber from "../../../services/validatePhoneNumber";
import InputField from "../../atoms/InputField";
import Button from "../../atoms/Button";
import styles from "./UserLookupForm.module.css";
import Dropdown from "../../atoms/Dropdown";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

export interface UserLookupFormProps {
  /** Allows passing a custom error message to display feedback */
  error?: string;
  onSubmit: (
    searchField: "email" | "subscriberId" | "accountId" | "phone",
    searchValue: string
  ) => void;
}

function UserLookupForm(props: UserLookupFormProps) {
  const isNotMedium = useMediaQuery("md");
  const isNotSmall = useMediaQuery("sm");
  const isSmall = !isNotSmall || !isNotMedium;
  const searchFieldOptions = [
    { value: "subscriberId", label: "Subscriber ID" },
    { value: "accountId", label: "Account ID" },
    { value: "email", label: "Email" },
    { value: "phone", label: "Phone" },
  ];

  const getFormControlType = (searchField: string) => {
    if (searchField === "subscriberId" || searchField === "accountId")
      return "number";
    else return "text";
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        searchField: "subscriberId",
        searchValue: "",
      }}
      onSubmit={(values) => {
        switch (values.searchField) {
          case "subscriberId":
            props.onSubmit("subscriberId", values.searchValue);
            break;
          case "accountId":
            props.onSubmit("accountId", values.searchValue);
            break;
          case "email":
            props.onSubmit("email", values.searchValue);
            break;
          case "phone":
            props.onSubmit("phone", values.searchValue);
            break;
        }
      }}
      validationSchema={Yup.object({
        searchField: Yup.mixed()
          .oneOf(["subscriberId", "accountId", "email", "phone"])
          .required(),
        searchValue: Yup.mixed()
          .when("searchField", {
            is: "subscriberId",
            then: Yup.number()
              .typeError("Subscriber ID must be numeric.")
              .required("Please enter a subscriber ID."),
          })
          .when("searchField", {
            is: "accountId",
            then: Yup.number()
              .typeError("Account ID must be numeric.")
              .required("Please enter an account ID."),
          })
          .when("searchField", {
            is: "email",
            then: Yup.string()
              .email("Please enter a valid email.")
              .required("Please enter an email."),
          })
          .when("searchField", {
            is: "phone",
            then: Yup.string().test(
              "phone",
              "Please enter a valid phone number.",
              validatePhoneNumber
            ),
          }),
      })}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        setFieldValue,
      }) => (
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div className={styles.title}>Locations Lookup</div>
            <form
              className={styles.form}
              onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
                handleSubmit(event)
              }
            >
              <InputField
                classes="w-full"
                name="searchValue"
                type={getFormControlType(values.searchField)}
                value={values.searchValue}
                onBlur={handleBlur}
                onChange={handleChange}
                invalid={
                  errors.searchField !== undefined ||
                  errors.searchValue !== undefined ||
                  (props.error !== undefined && props.error !== "")
                }
                hint={errors.searchValue ?? props.error}
                left={
                  <span>
                    <Dropdown
                      variant="link-white"
                      placeholder="Search..."
                      options={searchFieldOptions}
                      value={searchFieldOptions.find(
                        (s) => s.value === values.searchField
                      )}
                      onChange={(_e, value) =>
                        setFieldValue("searchField", value.value)
                      }
                    />
                  </span>
                }
                right={
                  <Button
                    variant="link-white"
                    type="submit"
                    icon={
                      !isSmall ? undefined : <FontAwesomeIcon icon={faSearch} />
                    }
                    label={!isSmall ? "Search" : undefined}
                  />
                }
              />
            </form>
          </div>
        </div>
      )}
    </Formik>
  );
}

export default UserLookupForm;
