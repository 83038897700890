import React from "react";

export default function MobileBusiness() {
  return (
    <svg
      width="151"
      height="40"
      viewBox="0 0 151 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="151" height="40" fill="black" />
      <path
        d="M0 11.6781C0 7.76613 2.26851 6.0033 5.2253 6.0033C7.09349 6.0033 8.63158 6.78288 9.53758 8.25074C10.4225 6.7899 11.9606 6.0033 13.8499 6.0033C16.7996 6.0033 19.0752 7.77316 19.0752 11.6781V17.5425C19.0752 18.3712 18.4009 19.0455 17.5722 19.0455C16.7434 19.0455 16.0692 18.3712 16.0692 17.5425V11.6781C16.0692 9.63432 15.0087 8.79855 13.5479 8.79855C12.1081 8.79855 11.0476 9.65539 11.0476 11.6781V17.5425C11.0476 18.3712 10.3733 19.0455 9.5446 19.0455C8.71586 19.0455 8.04163 18.3712 8.04163 17.5425V11.6781C8.04163 9.66241 6.95302 8.79855 5.52028 8.79855C4.05944 8.79855 2.99893 9.63432 2.99893 11.6781V17.5425C2.99893 18.3712 2.3247 19.0455 1.49595 19.0455C0.674233 19.0455 0 18.3712 0 17.5425V11.6781Z"
        fill="#00C900"
      />
      <path
        d="M46.1709 14.087V3.36251C46.1709 2.61102 46.7819 2 47.5334 2H47.8143C48.5658 2 49.1769 2.61102 49.1769 3.36251V6.41763H54.2476C54.9991 6.41763 55.6102 7.02865 55.6102 7.78014C55.6102 8.53163 54.9991 9.14265 54.2476 9.14265H49.1769V14.087C49.1769 15.7515 50.0337 16.4117 51.2698 16.4117C52.2039 16.4117 52.9273 15.7234 53.2152 14.6559C53.3978 13.9887 53.9878 13.5181 54.6761 13.5181C55.6593 13.5181 56.3827 14.4522 56.1369 15.4074C55.5189 17.7672 53.7069 19.214 51.2698 19.214C48.2428 19.214 46.1709 17.5425 46.1709 14.087Z"
        fill="#00C900"
      />
      <path
        d="M63.8838 11.643C63.8838 7.88553 66.1031 6.0946 69.3619 6.0946C72.0729 6.0946 74.0464 7.33772 74.6364 9.95037C74.833 10.8423 74.1658 11.6921 73.2528 11.6921H73.0983C72.4381 11.6921 71.8763 11.2286 71.7147 10.5895C71.3987 9.38149 70.5278 8.89688 69.369 8.89688C67.9081 8.89688 66.8968 9.6273 66.8968 11.65V17.388C66.8968 18.2167 66.2225 18.891 65.3938 18.891C64.565 18.891 63.8908 18.2167 63.8908 17.388V11.643H63.8838Z"
        fill="#00C900"
      />
      <path
        d="M79.3633 12.7737C79.3633 8.98818 82.1375 6.21399 86.0775 6.21399C90.0176 6.21399 92.7917 8.98818 92.7917 12.7737C92.7917 16.5592 90.0176 19.3334 86.0775 19.3334C82.1375 19.3334 79.3633 16.5592 79.3633 12.7737ZM89.7366 12.7737C89.7366 10.5754 88.2758 9.01627 86.0775 9.01627C83.8792 9.01627 82.3903 10.5825 82.3903 12.7737C82.3903 14.965 83.8792 16.5312 86.0775 16.5312C88.2688 16.5382 89.7366 14.972 89.7366 12.7737Z"
        fill="#00C900"
      />
      <path
        d="M32.4125 6.08752C28.4724 6.08752 25.6982 8.86171 25.6982 12.6472C25.6982 16.4328 28.4724 19.207 32.4125 19.207C34.1332 19.207 35.6221 18.6872 36.7528 17.7812C37.3007 17.3458 37.5114 16.5732 37.1602 15.9692C36.7107 15.2037 35.6432 15.0913 35.0602 15.6321C34.4352 16.208 33.5502 16.4819 32.4125 16.4819C30.8674 16.4819 29.6102 15.7445 29.0483 14.3469C28.9149 14.0098 28.8447 13.6516 28.8025 13.2864L28.7885 13.174C28.7182 12.563 28.7815 11.9941 28.8868 11.4533L28.9289 11.3058C29.4065 9.66236 30.7199 8.80552 32.4125 8.80552C34.0559 8.80552 35.4184 9.64129 35.896 11.3058H31.8225C31.1764 11.3058 30.6426 11.8185 30.6215 12.4646C30.6005 13.1389 31.1413 13.7007 31.8225 13.7007H38.017C38.607 13.7007 39.0775 13.2232 39.0775 12.6332C39.0705 8.86171 36.3455 6.08752 32.4125 6.08752Z"
        fill="#00C900"
      />
      <path
        d="M138.309 14.087V3.36251C138.309 2.61102 138.92 2 139.671 2H139.952C140.704 2 141.315 2.61102 141.315 3.36251V6.41763H146.385C147.137 6.41763 147.748 7.02865 147.748 7.78014C147.748 8.53163 147.137 9.14265 146.385 9.14265H141.315V14.087C141.315 15.7515 142.171 16.4117 143.407 16.4117C144.342 16.4117 145.065 15.7234 145.353 14.6559C145.536 13.9887 146.125 13.5181 146.814 13.5181C147.797 13.5181 148.52 14.4522 148.275 15.4074C147.657 17.7672 145.845 19.214 143.407 19.214C140.373 19.214 138.309 17.5425 138.309 14.087Z"
        fill="#9645FD"
      />
      <path
        d="M99.8145 12.3944C99.8145 8.22962 102.287 6.08752 105.974 6.08752C109.661 6.08752 112.133 8.20855 112.133 12.3944V17.3809C112.133 18.2097 111.459 18.8839 110.63 18.8839C109.802 18.8839 109.127 18.2097 109.127 17.3809V12.3944C109.127 10.0205 107.863 8.8898 105.974 8.8898C104.085 8.8898 102.82 10.0276 102.82 12.3944V17.3809C102.82 18.2097 102.146 18.8839 101.317 18.8839C100.489 18.8839 99.8145 18.2097 99.8145 17.3809V12.3944V12.3944Z"
        fill="#9645FD"
      />
      <path
        d="M125.4 6.08752C121.46 6.08752 118.686 8.86171 118.686 12.6472C118.686 16.4328 121.46 19.207 125.4 19.207C127.12 19.207 128.609 18.6872 129.74 17.7812C130.288 17.3458 130.499 16.5732 130.147 15.9692C129.698 15.2037 128.63 15.0913 128.048 15.6321C127.422 16.208 126.538 16.4819 125.4 16.4819C123.855 16.4819 122.597 15.7445 122.036 14.3469C121.902 14.0098 121.832 13.6516 121.79 13.2864L121.776 13.174C121.706 12.563 121.769 11.9941 121.874 11.4533L121.916 11.3058C122.394 9.66236 123.707 8.80552 125.4 8.80552C127.043 8.80552 128.406 9.64129 128.883 11.3058H124.81C124.164 11.3058 123.63 11.8185 123.609 12.4646C123.588 13.1389 124.129 13.7007 124.81 13.7007H131.004C131.594 13.7007 132.065 13.2232 132.065 12.6332C132.058 8.86171 129.333 6.08752 125.4 6.08752Z"
        fill="#9645FD"
      />
      <path
        d="M147.839 2.42144H147.383V2.03516H148.717V2.42144H148.261V3.71371H147.846V2.42144H147.839Z"
        fill="#9645FD"
      />
      <path
        d="M148.865 2.65316C148.865 2.20367 149.125 2 149.469 2C149.673 2 149.834 2.07726 149.933 2.23177C150.031 2.07726 150.193 2 150.396 2C150.733 2 151 2.20367 151 2.65316V3.71367H150.586V2.65316C150.586 2.45651 150.488 2.38628 150.368 2.38628C150.242 2.38628 150.15 2.45651 150.15 2.65316V3.71367H149.736V2.65316C149.736 2.45651 149.638 2.38628 149.518 2.38628C149.392 2.38628 149.301 2.45651 149.301 2.65316V3.71367H148.886V2.65316H148.865Z"
        fill="#9645FD"
      />
      <path
        d="M64.1787 32.7549V24.6219H66.2857V29.7279C66.9037 28.7516 67.9502 28.2249 69.2074 28.2249C71.3354 28.2249 73.5688 29.7489 73.5688 32.7408C73.5688 35.522 71.6163 37.4394 68.8351 37.4394C66.089 37.4324 64.1787 35.5853 64.1787 32.7549ZM71.4408 32.8251C71.4408 31.28 70.3943 30.1844 68.8562 30.1844C67.3111 30.1844 66.2857 31.28 66.2857 32.8251C66.2857 34.3702 67.3111 35.4659 68.8562 35.4659C70.3943 35.4659 71.4408 34.3632 71.4408 32.8251Z"
        fill="#9645FD"
      />
      <path
        d="M76.9541 33.0007V28.4496H79.0611V33.0007C79.0611 34.6652 79.946 35.4658 81.2734 35.4658C82.6008 35.4658 83.4857 34.6652 83.4857 33.0007V28.4496H85.5927V33.0007C85.5927 35.9223 83.858 37.4323 81.2664 37.4323C78.6888 37.4323 76.9541 35.9434 76.9541 33.0007Z"
        fill="#9645FD"
      />
      <path
        d="M89.0059 34.616H91.1339C91.155 35.1989 91.6677 35.5923 92.6931 35.5923C93.7746 35.5923 94.2382 35.1498 94.2382 34.616C94.2382 33.9418 93.4586 33.8575 92.5175 33.7311C91.169 33.5555 89.1885 33.3237 89.1885 31.1816C89.1885 29.3907 90.551 28.2249 92.665 28.2249C94.8071 28.2249 96.1204 29.4329 96.1766 30.9709H94.0837C94.0275 30.4372 93.5359 30.0649 92.7001 30.0649C91.78 30.0649 91.3165 30.5074 91.3165 31.0201C91.3165 31.6943 92.1172 31.7997 93.0513 31.9261C94.2241 32.0876 96.3662 32.3194 96.3662 34.4966C96.3662 36.2665 94.9335 37.4394 92.7001 37.4394C90.4597 37.4323 89.055 36.2454 89.0059 34.616Z"
        fill="#9645FD"
      />
      <path
        d="M99.7305 25.8931C99.7305 25.0784 100.313 24.4744 101.128 24.4744C101.943 24.4744 102.526 25.0784 102.526 25.8931C102.526 26.7078 101.943 27.2907 101.128 27.2907C100.313 27.2907 99.7305 26.7078 99.7305 25.8931ZM100.068 28.4285H102.175V37.1794H100.068V28.4285Z"
        fill="#9645FD"
      />
      <path
        d="M106.213 32.6496C106.213 29.7279 107.948 28.2179 110.539 28.2179C113.124 28.2179 114.866 29.7068 114.866 32.6496V37.2006H112.759V32.6496C112.759 30.9851 111.874 30.1844 110.546 30.1844C109.219 30.1844 108.334 30.9851 108.334 32.6496V37.2006H106.227V32.6496H106.213Z"
        fill="#9645FD"
      />
      <path
        d="M118.244 32.8251C118.244 30.1704 120.197 28.2179 122.957 28.2179C125.717 28.2179 127.634 30.1704 127.634 32.8251V33.5696H120.456C120.737 34.8478 121.714 35.5221 122.957 35.5221C123.898 35.5221 124.586 35.2552 125.029 34.6863H127.276C126.623 36.3508 125.029 37.4324 122.95 37.4324C120.19 37.4324 118.244 35.4799 118.244 32.8251ZM125.401 31.884C125.064 30.7111 124.109 30.1282 122.957 30.1282C121.77 30.1282 120.85 30.7322 120.513 31.884H125.401Z"
        fill="#9645FD"
      />
      <path
        d="M130.682 34.616H132.81C132.831 35.1989 133.343 35.5923 134.369 35.5923C135.45 35.5923 135.914 35.1498 135.914 34.616C135.914 33.9418 135.134 33.8575 134.193 33.7311C132.845 33.5555 130.864 33.3237 130.864 31.1816C130.864 29.3907 132.227 28.2249 134.341 28.2249C136.483 28.2249 137.796 29.4329 137.852 30.9709H135.759C135.703 30.4372 135.212 30.0649 134.376 30.0649C133.456 30.0649 132.992 30.5074 132.992 31.0201C132.992 31.6943 133.793 31.7997 134.727 31.9261C135.9 32.0876 138.042 32.3194 138.042 34.4966C138.042 36.2665 136.609 37.4394 134.376 37.4394C132.135 37.4323 130.738 36.2454 130.682 34.616Z"
        fill="#9645FD"
      />
      <path
        d="M141.104 34.616H143.233C143.254 35.1989 143.766 35.5923 144.792 35.5923C145.873 35.5923 146.337 35.1498 146.337 34.616C146.337 33.9418 145.557 33.8575 144.616 33.7311C143.268 33.5555 141.287 33.3237 141.287 31.1816C141.287 29.3907 142.65 28.2249 144.764 28.2249C146.906 28.2249 148.219 29.4329 148.275 30.9709H146.182C146.126 30.4372 145.634 30.0649 144.799 30.0649C143.879 30.0649 143.415 30.5074 143.415 31.0201C143.415 31.6943 144.216 31.7997 145.15 31.9261C146.323 32.0876 148.465 32.3194 148.465 34.4966C148.465 36.2665 147.032 37.4394 144.799 37.4394C142.558 37.4323 141.154 36.2454 141.104 34.616Z"
        fill="#9645FD"
      />
    </svg>
  );
}
