import React, { ReactElement, useCallback, useEffect, useState } from "react";
import { Redirect, Route, Router, Switch } from "react-router";
// import './App.scss'
import history from "./services/history";
import LoginPage from "./pages/LoginPage";
import LocationsPage from "./pages/LocationsPage";
import ApiService from "./services/ApiService";
import { User } from "./models/User";
import ProtectedRoute from "./components/modules/ProtectedRoute";
import AdminLoginPage from "./pages/AdminLoginPage";
import AdminLocationPage from "./pages/AdminLocationPage";
import AlertContext from "./contexts/AlertContext";
import { ApiResponse } from "./models/ApiResponses";
import ApiResponseAlert from "./components/modules/ApiResponseAlert";
import NavBar from "./components/modules/NavBar";
import DialogOpenContext from "./contexts/DialogOpenContext";

function App() {
  const apiService = useCallback(() => new ApiService(), []);
  const [currentUser, setCurrentUser] = useState<User | null>();
  const [alert, setAlert] = useState<ApiResponse | null>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const checkUserData = () => {
    const user = apiService().getCurrentUser();
    setCurrentUser(user);
  };

  const handleLoginCheck = (defaultDestination: ReactElement) => {
    const user = apiService().getCurrentUser();
    if (user && user.role.includes("Customer")) {
      return <Redirect to="/locations" />;
    } else if (user && user.role.includes("Admin")) {
      return <Redirect to="/admin" />;
    } else {
      return defaultDestination;
    }
  };

  useEffect(() => {
    checkUserData();
    window.addEventListener("storage", () => checkUserData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DialogOpenContext.Provider value={{ isDialogOpen, setIsDialogOpen }}>
      <AlertContext.Provider value={{ alert, setAlert }}>
        <Router history={history}>
          <NavBar
            user={currentUser}
            onSignOut={() => {
              apiService().signOut();
              checkUserData();
            }}
          />
          {!isDialogOpen && (
            <ApiResponseAlert
              apiResponse={alert}
              onClose={() => setAlert(null)}
            />
          )}
          <Switch>
            <Route
              exact
              path="/"
              render={(routeProps) =>
                handleLoginCheck(<Redirect to="/login" />)
              }
            />
            <Route
              exact
              path="/login"
              render={(routeProps) =>
                handleLoginCheck(<LoginPage onSignIn={() => checkUserData()} />)
              }
            />
            <Route
              exact
              path="/admin/login"
              render={() =>
                handleLoginCheck(
                  <AdminLoginPage onSignIn={() => checkUserData()} />
                )
              }
            />
            <ProtectedRoute
              exact
              path="/locations"
              user={apiService().getCurrentUser()}
              component={LocationsPage}
            />
            <ProtectedRoute
              isAdminOnly
              exact
              path="/admin"
              redirect="/admin/login"
              user={apiService().getCurrentUser()}
              component={AdminLocationPage}
            />
            <Route path="*" render={() => <Redirect to="/"></Redirect>} />
          </Switch>
        </Router>
      </AlertContext.Provider>
    </DialogOpenContext.Provider>
  );
}

export default App;
