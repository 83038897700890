import React, { memo, useState } from "react";
import Button from "../../atoms/Button";
import UploadIcon from "../UploadIcon";
import CancelIcon from "../CancelIcon";
import { InputField } from "../../atoms/InputField";

interface FileBrowseModalProps {
  /** If submitting is true, display the selected file name instead of the input control. */
  isSubmitting: boolean;
  file?: File;
  onImport(file: File): void;
  onCancel(): void;
}

const FileBrowseForm = memo((props: FileBrowseModalProps) => {
  const [selectedFile, setSelectedFile] = useState<File | undefined>(
    props.file
  );

  const handleOkClick = () => {
    if (selectedFile) props.onImport(selectedFile);
  };

  const handleCancelClick = () => {
    props.onCancel();
  };

  const renderFileControl = () => {
    if (props.isSubmitting) {
      return <p className="text-white">{selectedFile?.name}</p>;
    } else {
      return (
        <InputField
          type="file"
          accept=".xlsx"
          onChange={(e: any) => setSelectedFile(e.target.files[0])}
        />
      );
    }
  };

  return (
    <>
      {renderFileControl()}
      <div className="flex flex-row justify-end gap-5">
        <Button
          label="Import"
          variant="outline-green"
          icon={<UploadIcon isUploading={props.isSubmitting} />}
          disabled={props.isSubmitting || selectedFile === undefined}
          onClick={() => handleOkClick()}
        />
        <Button
          label="Cancel"
          variant="outline-white"
          icon={<CancelIcon />}
          disabled={props.isSubmitting}
          onClick={() => handleCancelClick()}
        />
      </div>
    </>
  );
});

export default FileBrowseForm;
