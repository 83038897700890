import axios, { AxiosError, AxiosRequestConfig } from "axios";
import LocalStorageService from "./LocalStorageService";
import history from "../services/history";

function useFetchClient() {
  const defaultOptions: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_API_PATH,
    headers: {
      "Content-Type": "application/json",
    },
    withCredentials: true,
  };

  // Create instance
  const instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use((config) => {
    const localStorageService = new LocalStorageService();
    const token = localStorageService.getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  });

  instance.interceptors.response.use(
    undefined,
    async (originalError: AxiosError) => {
      const localStorageService = new LocalStorageService();
      const currentToken = localStorageService.getAccessToken();

      if (
        originalError.response?.status === 401 &&
        currentToken &&
        currentToken !== ""
      ) {
        // attempt to refresh access token
        return axios
          .post(`/user/auth/token`, undefined, {
            baseURL: process.env.REACT_APP_API_URL,
            headers: {
              Authorization: `Bearer ${
                localStorageService.getAccessToken() ?? ""
              }`,
            },
            withCredentials: true,
          })
          .then((response) => {
            if (response.status === 200) {
              localStorageService.setAccessToken(response.data.access_token);
              originalError.config.headers["Authorization"] =
                "Bearer " + response.data.access_token;
              return axios.request(originalError.config);
            }
          })
          .catch((error: AxiosError) => {
            if (
              error.response?.status === 401 ||
              error.response?.status === 403
            ) {
              const user = localStorageService.getUser();
              let path = "/login";
              if (user && user.role.includes("Admin")) path = `/admin/login`;
              localStorageService.clear();
              history.push(path);
            }

            return Promise.reject(error);
          });
      }

      return Promise.reject(originalError);
    }
  );

  return instance;
}

export default useFetchClient;
