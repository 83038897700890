import React, { useCallback, useMemo } from "react";
import ApiService from "../services/ApiService";
import LocationsView from "../components/modules/LocationsView";
import history from "../services/history";

function LocationsPage() {
  const apiService = useCallback(() => new ApiService(), []); // creates the api service for use in this component
  const user = useMemo(() => apiService().getCurrentUser(), [apiService]);

  if (user === null) {
    history.push("/login");
  } else {
    return <LocationsView currentUser={user} />;
  }
}

export default LocationsPage;
