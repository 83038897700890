import React from "react";
import clsx from "clsx";
import { paramCase, pascalCase } from "change-case";
import * as ReactFeather from "react-feather";

import * as AssetIcons from "./assets";
import styles from "./Icon.module.css";

/** Converts type of string literals from PascalCase to param-case */
// eslint-disable-next-line prettier/prettier
type ParamCase<S> = S extends `${infer C}${infer T}`
  ? ParamCase<T> extends infer U
    ? U extends string
      ? T extends Uncapitalize<T>
        ? `${Uncapitalize<C>}${U}`
        : `${Uncapitalize<C>}-${U}`
      : never
    : never
  : S;

type AssetIconName = keyof typeof AssetIcons;
type FeatherIconName = keyof typeof ReactFeather;
type CanonicalIconName = AssetIconName | FeatherIconName;
type ParamIconName = ParamCase<CanonicalIconName>;

type Aliases<Key extends string> = Record<Key, ParamIconName>;

// This is a bit of a hack to allow better typing
function createAliases<Key extends string>(map: Aliases<Key>): typeof map {
  return map;
}

const ICON_ALIASES = createAliases({
  email: "mail",
  featured: "zap",
  healthcare_coverage: "plus-circle",
  "401K": "dollar-sign",
  PTO: "calendar",
  paid_disability: "umbrella",
  paid_holiday: "sun",
  paid_life_and_AD_and_D: "heart",
  FSA: "maximize2",
  clear: "sun",
  file_sharing: "file",
  speed: "zap",
  install: "download",
  TV: "tv",
  cost_or_price: "dollar-sign",
  up_time: "arrow-up-circle",
  support: "user-check",
  scalable: "maximize2",
  customization: "settings",
  reliable_or_guaranteed: "thumbs-up",
  maintenance: "tool",
  content: "edit",
  equipment: "hard-drive",
  performance: "trending-up",
  future_ready: "clock",
  symmetrical_speed: "zap",
  metronet_business: "metronet-business",
  metronet_residential: "home",
  metronet_contact_us: "headphones",
  metronet_search: "search",
});

type AliasIconName = keyof typeof ICON_ALIASES;

export type IconName = AliasIconName | ParamIconName;

export const ICON_NAMES = [
  ...Object.keys(ReactFeather).map((pascal) => paramCase(pascal)),
  ...Object.keys(AssetIcons).map((pascal) => paramCase(pascal)),
  ...Object.keys(ICON_ALIASES),
] as IconName[];

/** Resolves aliases and param-case icon names to their PascalCase canonical name */
function getCanonicalName(name: IconName): CanonicalIconName {
  const paramName = ICON_ALIASES[name as AliasIconName] ?? name;
  return pascalCase(paramName) as CanonicalIconName;
}

export type Props = {
  className?: string;
  color?: string;
  name?: IconName;
  /** Size in pixels, defaults to 24 */
  size?: number;
};

export function Icon({
  className,
  color,
  name,
  size = 24,
}: Props): JSX.Element | null {
  if (!name) return null;

  const canonicalName = getCanonicalName(name);

  const Svg: React.FC | null =
    AssetIcons[canonicalName as AssetIconName] ??
    ReactFeather[canonicalName as FeatherIconName] ??
    null;

  if (!Svg) return null;

  return (
    <span
      className={clsx(styles.Icon, className)}
      role="img"
      style={{
        color,
        width: size,
        height: size,
      }}
    >
      <Svg />
    </span>
  );
}
