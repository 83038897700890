import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import validatePhoneNumber from "../../../services/validatePhoneNumber";
import InputField from "../../atoms/InputField";
import Button from "../../atoms/Button";
import styles from "./PhonePinForm.module.css";

export interface PhonePinFormProps {
  phoneNumber?: string;
  onSubmit(phoneNumber: string, pin: string): void;
}

function PhonePinForm(props: PhonePinFormProps) {
  return (
    <Formik
      initialValues={{ phoneNumber: props.phoneNumber ?? "", pin: "" }}
      validationSchema={Yup.object({
        phoneNumber: Yup.string()
          .test(
            "phone",
            "Please enter a valid phone number.",
            validatePhoneNumber
          )
          .required(),
        pin: Yup.string()
          .matches(/\d\d\d\d/)
          .required(),
      })}
      onSubmit={(values, { setSubmitting }) => {
        props.onSubmit(values.phoneNumber, values.pin);
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form
          className={styles.loginForm}
          onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
            handleSubmit(event)
          }
        >
          <InputField
            name="phoneNumber"
            placeholder="Phone Number"
            value={values.phoneNumber}
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={
              touched.phoneNumber !== undefined &&
              errors.phoneNumber !== undefined
            }
            hint={errors.phoneNumber}
          />

          <InputField
            type="password"
            name="pin"
            value={values.pin}
            placeholder="Pin Number"
            onBlur={handleBlur}
            onChange={handleChange}
            invalid={touched.pin !== undefined && errors.pin !== undefined}
          />

          <Button
            classes={styles.loginFormSubmit}
            size="md"
            variant="outline-green"
            type="submit"
            label="Sign In"
          />
        </form>
      )}
    </Formik>
  );
}

export default PhonePinForm;
