import React from "react";
import FileBrowseForm from "../components/modules/FileBrowseForm";
import ModalWrapper from "../components/modules/ModalWrapper";
import useModal from "./useModal";

export interface FileBrowseDialogProps {
  file?: File;
  importing: boolean;
  onImport: (file: File) => void;
  onCancel: () => void;
}

export interface FileBrowseDialog {
  show(): void;
  hide(): void;
  render(props: FileBrowseDialogProps): JSX.Element;
}

function useFileBrowseDialog(): FileBrowseDialog {
  const { show, hide, RenderModal: RenderFileBrowse } = useModal(); // control methods for showing the file browse dialog

  const renderDialog = (props: FileBrowseDialogProps) => (
    <RenderFileBrowse>
      <ModalWrapper title="Import From Excel" onClose={props.onCancel}>
        <FileBrowseForm
          isSubmitting={props.importing}
          file={props.file}
          onImport={(file) => props.onImport(file)}
          onCancel={props.onCancel}
        />
      </ModalWrapper>
    </RenderFileBrowse>
  );

  return {
    show,
    hide,
    render: renderDialog,
  };
}

export default useFileBrowseDialog;
