import clsx from "clsx";
import React, { memo, ReactChild } from "react";
import ReactDOM from "react-dom";
import useMediaQuery from "../../../hooks/useMediaQuery";
import Button from "../../atoms/Button";
import CancelIcon from "../CancelIcon";
import styles from "./ModalWrapper.module.css";

export interface ModalWrapperProps {
  title: string;
  children: ReactChild;
  size?: "sm" | "lg" | undefined;
  isStatic?: boolean; // If true, user can't click background to dismiss.
  onClose(): void;
}

const ModalWrapper = memo((props: ModalWrapperProps) => {
  const portal = document.getElementById("modal-root");
  const isLarge = useMediaQuery("lg");
  const isMedium = useMediaQuery("md");
  const isSmall = useMediaQuery("sm");

  if (!portal) return null;

  return ReactDOM.createPortal(
    <div
      className={clsx(styles.container, "items-center justify-center", {
        "items-center": !isSmall,
        "justify-center": !isSmall,
      })}
      aria-labelledby="dialogTitle"
      aria-modal="true"
      onClick={(e) => {
        if (e.target !== e.currentTarget) console.log("targets not the same");
        if (!props.isStatic) props.onClose();
      }}
    >
      <div
        className={clsx(styles.dialog, "scrollbar", {
          "w-6/12": isLarge,
          "w-full": !isMedium,
          "ml-5 mr-5": isMedium,
        })}
        onClick={(e) => e.stopPropagation()}
      >
        <div className={styles.dialogContent}>
          <div className={styles.dialogHeader}>
            <h5 className={styles.dialogTitle} id="dialogTitle">
              {props.title}
            </h5>
            <Button
              variant="link-white"
              icon={<CancelIcon />}
              classes={styles.dialogCloseBtn}
              onClick={props.onClose}
            />
          </div>
          <div className={styles.dialogBody}>{props.children}</div>
        </div>
      </div>
    </div>,
    portal
  );
});

export default ModalWrapper;
