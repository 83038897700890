import React, { useState, useCallback, useEffect, useContext } from "react";
import EmailLoginForm from "../components/modules/EmailLoginForm";
import PhonePinForm from "../components/modules/PhonePinForm";
import ApiService from "../services/ApiService";
import history from "../services/history";
import LoadingIndicator from "../components/modules/LoadingIndicator";
import Button from "../components/atoms/Button";
import AlertContext from "../contexts/AlertContext";

export interface LoginPageProps {
  usePhoneAndPin?: boolean;
  onSignIn(): void;
}

function LoginPage(props?: LoginPageProps) {
  const apiService = useCallback(() => new ApiService(), []);
  const { setAlert } = useContext(AlertContext);
  const [usePhoneAndPin, setUsePhoneAndPin] = useState(
    props?.usePhoneAndPin === undefined ? false : props.usePhoneAndPin
  );
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);

  const handleUserLogin = async (email: string, password: string) => {
    setAlert(null);
    setLoading(true);
    setUsername(email);
    const response = await apiService().signIn({
      user: email,
      pass: password,
      authType: "CustomerEmail",
    });
    if (response.signedIn) {
      setLoading(false);

      if (props?.onSignIn) {
        props.onSignIn();
      }

      history.push("/");
    } else {
      setAlert(response);
      setLoading(false);
    }
  };

  const handlePhoneLogin = async (phoneNumber: string, pin: string) => {
    setAlert(null);
    setLoading(true);
    setUsername(phoneNumber);

    // * Remove phone number formatting before sending request
    let numberToLogin = phoneNumber.startsWith("1-")
      ? phoneNumber.replace("1-", "")
      : phoneNumber;
    numberToLogin = numberToLogin
      .replace("+1", "")
      .replace("(", "")
      .replace(")", "")
      .replaceAll("-", "")
      .replaceAll(" ", "");

    console.log(`logging in ${numberToLogin}`);
    const response = await apiService().signIn({
      user: numberToLogin,
      pass: pin,
      authType: "CustomerPhone",
    });

    setAlert(response);

    if (response.signedIn) {
      if (props?.onSignIn) {
        props.onSignIn();
      }

      history.push("/");
    } else {
      setLoading(false);
    }
  };

  useEffect(() => {
    setUsername("");
    setAlert(null);
  }, [setAlert, usePhoneAndPin]);

  const renderForm = () => {
    if (usePhoneAndPin) {
      return (
        <PhonePinForm
          phoneNumber={username}
          onSubmit={(phone, pin) => handlePhoneLogin(phone, pin)}
        />
      );
    } else {
      return (
        <EmailLoginForm
          email={username}
          onSubmit={(email, password) => handleUserLogin(email, password)}
        />
      );
    }
  };

  if (loading) return <LoadingIndicator loading={loading} />;

  return (
    <div className="grid grid-cols-12 mt-10">
      <section className="p-3 col-start-2 col-span-10 lg:col-start-5 lg:col-span-4 bg-gray-950 text-white rounded-md">
        <div className="text-lg text-center w-full">E911 Management</div>
        <div>
          {renderForm()}
          <div className="flex flex-row justify-end">
            <Button
              variant="link-white"
              label={`Login with ${usePhoneAndPin ? "Email" : "Phone number"}`}
              onClick={() => {
                setUsername("");
                setUsePhoneAndPin(!usePhoneAndPin);
              }}
            />
          </div>
        </div>
      </section>
    </div>
  );
}

export default LoginPage;
