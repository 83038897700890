import React, { useState } from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { User } from "../../../models/User";
import Button from "../../atoms/Button";
import Logo from "../../atoms/Logo";
import SelectArrowIcon from "../SelectArrowIcon";
import styles from "./NavBar.module.css";

export interface NavBarProps {
  user: User | null | undefined;
  onSignOut: () => void;
}

export default function NavBar({ user, onSignOut }: NavBarProps) {
  const isNotMedium = useMediaQuery("md");
  const isNotSmall = useMediaQuery("sm");
  const isSmall = !isNotSmall || !isNotMedium;
  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  return (
    <nav className={styles.navbar}>
      <a className={styles.navbarBrand} href="/">
        {!isSmall && <Logo />}
        <span className={styles.navbarHeader}>E911 Management</span>
      </a>
      {user && (
        <div className={styles.navMenu}>
          <div className={styles.navMenuAvatarContainer}>
            <Button
              icon={
                <span className={styles.navMenuAvatar}>
                  <span>{user.initials}</span>
                </span>
              }
              variant="link-white"
              label={!isSmall ? user.displayName : undefined}
              onClick={() => setMenuVisible(!menuVisible)}
            />
            <SelectArrowIcon isExpanded={menuVisible} />
          </div>

          {menuVisible && (
            <div
              className="h-screen w-screen fixed top-0 left-0 z-10"
              onClick={() => setMenuVisible(false)}
            >
              <ul className={styles.navMenuDropdown}>
                <li className={styles.navMenuDropdownItem}>
                  <Button
                    classes={styles.naveMenuDropdownButton}
                    label="Sign Out"
                    variant="link-white"
                    onClick={() => {
                      setMenuVisible(false);
                      onSignOut();
                    }}
                  />
                </li>
              </ul>
            </div>
          )}
        </div>
      )}
    </nav>
  );
}
