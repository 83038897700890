import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import SelectArrowIcon from "../../modules/SelectArrowIcon";
import Button from "../Button";
import InputField from "../InputField";
import styles from "./Typeahead.module.css";
import { v4 as generateUuid } from "uuid";

export interface TypeaheadOption {
  label: string;
  value: string;
}

export interface Props {
  name?: string;
  label?: string;
  placeholder?: string;
  value?: TypeaheadOption;
  options?: TypeaheadOption[];
  hint?: string;
  isInvalid?: boolean;
  onChange?: (value: TypeaheadOption | undefined) => void;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement, Element> | undefined
  ) => void;
}

export default function Typeahead({
  name,
  label,
  placeholder,
  value,
  options,
  hint,
  isInvalid,
  onChange,
  onBlur,
}: Props) {
  const [search, setSearch] = useState<string>();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [filtered, setFiltered] = useState<TypeaheadOption[]>([]);
  const [highlightIndex, setHighlightIndex] = useState<number>(0);
  const list = useRef<HTMLUListElement | null>(null);
  const listItems = useRef<(HTMLLIElement | null)[]>([]);

  const getSelectedLabel = (option: TypeaheadOption | undefined) =>
    option?.label;

  const handleFocus = (
    e: React.FocusEvent<HTMLInputElement, Element> | undefined
  ) => {
    e?.target.select();
    setIsSearching(true);
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement, Element> | undefined
  ) => {
    setIsSearching(false);
    if (onBlur) onBlur(e);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    const value = e?.target.value;
    setSearch(value);
    if (value && value !== "") setIsSearching(true);
    if (onChange !== undefined) onChange(undefined);
  };

  const handleSelection = (o: TypeaheadOption) => {
    setIsSearching(false);
    setHighlightIndex(0);
    if (onChange !== undefined) onChange(o);
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement> | undefined
  ) => {
    e?.stopPropagation();
    e?.nativeEvent.stopPropagation();

    if (e) {
      if (e.key === "Tab") {
        if (filtered.length === 1) handleSelection(filtered[0]);
      } else if (e.key === "Enter") {
        if (!isSearching) setIsSearching(true);
        else if (filtered.length === 1) handleSelection(filtered[0]);
        else if (isSearching) handleSelection(filtered[highlightIndex]);
      } else if (e.key === "ArrowDown") {
        const newIndex = highlightIndex + 1;
        if (newIndex < filtered.length) {
          setHighlightIndex(newIndex);
          listItems.current[newIndex]?.scrollIntoView();
        }
      } else if (e.key === "ArrowUp") {
        const newIndex = highlightIndex - 1;
        if (newIndex >= 0) {
          setHighlightIndex(newIndex);
          listItems.current[newIndex]?.scrollIntoView();
        }
      }
    }
  };

  useEffect(() => {
    setSearch(getSelectedLabel(value));
  }, [value]);

  useEffect(() => {
    setFiltered(
      options?.filter((o) =>
        o.label.toLocaleLowerCase().includes(search?.toLocaleLowerCase() ?? "")
      ) ?? []
    );
  }, [options, search]);

  const renderOptions = () => {
    if (options && options.length > 0) {
      return filtered.map((o, i) => (
        <li
          ref={(ref) => {
            if (i < listItems.current.length) listItems.current[i] = ref;
            else listItems.current.push(ref);
          }}
          onMouseDown={() => handleSelection(o)}
          onMouseEnter={() => setHighlightIndex(i)}
          className={
            i === highlightIndex
              ? clsx(styles.dropdownItem, styles.dropdownItemHighlight)
              : styles.dropdownItem
          }
        >
          <Button variant="link-white" label={o.label} />
        </li>
      ));
    } else {
      return (
        <li>
          <span>No matches available.</span>
        </li>
      );
    }
  };

  const id = generateUuid();

  return (
    <div className="relative">
      <InputField
        name={name}
        label={label}
        placeholder={placeholder}
        value={search ?? ""}
        invalid={isInvalid}
        hint={hint}
        autoComplete={`autoComplete_disabled_${id}`} // Set random unique ID value to force autocomplete to be disabled. Edge-Chromium ignores autoComplete="off"
        onFocus={handleFocus}
        onBlur={handleBlur}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        right={<SelectArrowIcon isExpanded={isSearching} />}
      />
      {isSearching && (
        <ul
          ref={(ref) => (list.current = ref)}
          className={clsx(styles.dropdown, "scrollbar")}
        >
          {renderOptions()}
        </ul>
      )}
    </div>
  );
}
