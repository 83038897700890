import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

export interface Props {
  /** Toggles the icons for up / down arrow directions. Displays an upwards arrow icon when isExpanded is true. */
  isExpanded?: boolean;
}

export default function SelectArrowIcon({ isExpanded }: Props) {
  return <FontAwesomeIcon icon={isExpanded ? faChevronUp : faChevronDown} />;
}
