import React from "react";
import TE911Location from "../models/TE911Location";
import { User, UserLookupResponse } from "../models/User";
import useFetchClient from "./fetchClient";
import history from "./history";
import { AuthRequest } from "../models/ApiRequests";
import {
  LoginResponse,
  UpdateLocationResponse,
  ExportLocationsResponse,
  ImportLocationsResponse,
  AuthResponse,
  GetLocationsResponse,
} from "../models/ApiResponses";
import { AxiosError } from "axios";
import LocalStorageService from "./LocalStorageService";

class ApiService {
  readonly localStorageService = new LocalStorageService();
  readonly apiUrl = process.env.REACT_APP_API_URL ?? "https://localhost:5001";
  readonly fetchClient = useFetchClient();
  readonly STATUS_401_MSG = {
    status: 401,
    message: "Please login to continue.",
  };

  readonly STATUS_403_MSG = {
    status: 403,
    message: "You are not authorized to perform that request.",
  };

  readonly STATUS_500_MSG = {
    status: 500,
    message: "Something went wrong while processing the request.",
  };

  readonly REQUEST_FAILED_MSG = {
    status: 1,
    message: "We were unable to send the request.",
  };

  readonly UNKNOWN_ERR_MSG = {
    status: 0,
    message: "An unknown error occurred while performing the request.",
  };

  private errorHandler(
    error: AxiosError,
    messages: { status: number; message: string | React.ReactChild }[]
  ) {
    if (process.env.NODE_ENV === "development") console.debug(error);
    const message = messages.find((m) => m.status === error.response?.status);
    const unknownErr =
      messages.find((m) => m.status === 0) ?? this.UNKNOWN_ERR_MSG;
    const requestFailed =
      messages.find((m) => m.status === 1) ?? this.REQUEST_FAILED_MSG;

    if (!error.response && error.request) {
      return requestFailed.message;
    } else if (message) {
      return message.message;
    } else {
      return unknownErr.message;
    }
  }

  async signIn(request: AuthRequest): Promise<LoginResponse> {
    const isPhoneRequest = request.authType === "CustomerPhone";

    let loginResponse: LoginResponse = {
      status: 500,
      signedIn: false,
      message: "",
      requestSucceeded: false,
    };

    try {
      const response = await this.fetchClient.post<AuthResponse>(
        `${this.apiUrl}/user/auth/`,
        request
      );
      if (response.status === 200) {
        const user = response.data.user;

        // set the user initials
        if (user.displayName) {
          const displayNameSplit = user.displayName.split(" ");
          let initials = "";
          displayNameSplit.forEach((s) => (initials += s[0]));
          user.initials = initials.toUpperCase();
        } else {
          user.initials = "P";
        }

        loginResponse.status = 200;
        loginResponse.requestSucceeded = true;
        loginResponse.signedIn = true;
        this.localStorageService.setUser(user);
        this.localStorageService.setAccessToken(response.data.access_token);
      }
    } catch (error) {
      const axiosError = error as AxiosError;

      let message: string | null | undefined = axiosError.response?.data;
      if (!message || message === "") {
        message = isPhoneRequest
          ? "Phone number or pin is incorrect, please try again."
          : "Username or password is incorrect, please try again.";
      }

      loginResponse.requestSucceeded = false;
      loginResponse.signedIn = false;
      loginResponse.status = axiosError.response?.status ?? 500;
      loginResponse.message = this.errorHandler(axiosError, [
        {
          status: 401,
          message: message,
        },
        {
          status: 403,
          message: (
            <span>
              Account is locked. Contact{" "}
              <a
                className="text-info"
                href="mailto:customer_service@metronetinc.com"
              >
                customer service
              </a>{" "}
              for assistance.
            </span>
          ),
        },
        this.STATUS_500_MSG,
      ]);
    }

    return loginResponse;
  }

  signOut() {
    const path = this.localStorageService.getUser()?.role.includes("Admin")
      ? "/admin/login"
      : "/login";
    this.localStorageService.clear();
    history.push(path);
  }

  getCurrentUser(): User | null {
    return this.localStorageService.getUser();
  }

  async getUser(
    searchField: "email" | "subscriberId" | "accountId" | "phone",
    searchValue: string
  ): Promise<UserLookupResponse | null> {
    return this.fetchClient
      .get<UserLookupResponse>(
        `${this.apiUrl}/user?${searchField}=${searchValue}`
      )
      .then((response) => {
        if (response.status === 200 && response.data) {
          return response.data;
        }

        return null;
      })
      .catch((error: AxiosError) => {
        if (process.env.NODE_ENV === "development") {
          this.errorHandler(error, [
            this.STATUS_401_MSG,
            this.STATUS_403_MSG,
            this.STATUS_500_MSG,
          ]);
        }
        return null;
      });
  }

  async getLocations(
    subscriberId: string,
    phoneNumber?: string
  ): Promise<GetLocationsResponse> {
    let params = `?subscriberId=${subscriberId}`;
    if (phoneNumber) params += `&phonenumber=${phoneNumber}`;

    return this.fetchClient
      .get<TE911Location[]>(`${this.apiUrl}/locations${params}`)
      .then((response) => {
        const locations = response?.data;
        locations?.forEach((l) => {
          l.phoneNumberFormatted = this.formatPhoneNumber(l.phoneNumber);
        });

        return {
          status: response.status,
          requestSucceeded: true,
          message: "",
          locations,
        };
      })
      .catch((error: AxiosError) => {
        return {
          status: error.response?.status ?? 500,
          requestSucceeded: false,
          message: this.errorHandler(error, [
            this.STATUS_401_MSG,
            this.STATUS_403_MSG,
            {
              status: 500,
              message: "Failed to fetch locations.",
            },
          ]),
          locations: [],
        };
      });
  }

  async updateLocation(
    location: TE911Location
  ): Promise<UpdateLocationResponse> {
    return this.fetchClient
      .put<TE911Location>(`${this.apiUrl}/locations`, location)
      .then((response) => {
        let result: UpdateLocationResponse = {
          status: response.status,
          location: null,
          message: "",
          requestSucceeded: false,
        };

        result.location = response.data;
        if (result.location) {
          result.location.phoneNumberFormatted = this.formatPhoneNumber(
            result.location.phoneNumber
          );
          result.message = `The location has been updated for ${result.location?.phoneNumberFormatted}!`;
          result.requestSucceeded = true;
        }
        return result;
      })
      .catch((error: AxiosError) => {
        return {
          status: error.response?.status ?? 500,
          location: null,
          message: this.errorHandler(error, [
            this.STATUS_401_MSG,
            this.STATUS_403_MSG,
            {
              status: 500,
              message:
                error.response?.data ??
                `We were unable to update the location for ${location.phoneNumberFormatted}.`,
            },
          ]),
          requestSucceeded: false,
        };
      });
  }

  async exportLocations(
    subscriberId: string
  ): Promise<ExportLocationsResponse | undefined> {
    return this.fetchClient
      .get(`${this.apiUrl}/locations/export?subscriberId=${subscriberId}`)
      .then((response) => {
        const exportResponse: ExportLocationsResponse = {
          status: response.status,
          message: "",
          requestSucceeded: false,
          contentType: "",
          fileContents: undefined,
          fileDownloadName: "",
        };

        exportResponse.message = "";
        exportResponse.requestSucceeded = true;
        exportResponse.contentType = response.headers["content-type"];

        // get file name
        const disposition = response.headers["content-disposition"];
        const start = disposition.indexOf("filename=") + 9;
        const end = disposition.indexOf(";", start);
        const filename = disposition.substring(start, end);
        exportResponse.fileDownloadName = filename;

        // convert base64 to blob in 512 chunks
        const byteChars = atob(response.data);
        const byteArrays = [];
        for (let offset = 0; offset < byteChars.length; offset += 512) {
          const slice = byteChars.slice(offset, offset + 512);
          const byteNums = new Array(slice.length);
          for (let i = 0; i < slice.length; i++) {
            byteNums[i] = slice.charCodeAt(i);
          }
          byteArrays.push(new Uint8Array(byteNums));
        }

        exportResponse.fileContents = new Blob(byteArrays, {
          type: exportResponse.contentType,
        });
        return exportResponse;
      })
      .catch((error: AxiosError) => {
        return {
          status: error.response?.status ?? 500,
          message: this.errorHandler(error, [
            this.STATUS_401_MSG,
            this.STATUS_403_MSG,
            {
              status: 500,
              message: "We were unable to export the information.",
            },
          ]),
          requestSucceeded: false,
          contentType: "",
          fileContents: undefined,
          fileDownloadName: "",
        };
      });
  }

  async importLocations(
    subscriberId: string,
    file: File
  ): Promise<ImportLocationsResponse> {
    const formData = new FormData();
    formData.append("subscriberId", subscriberId);
    formData.append("importfile", file, file.name);

    return this.fetchClient
      .post<ImportLocationsResponse>(
        `${this.apiUrl}/locations/import`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then(async (response) => {
        const importResponse: ImportLocationsResponse = {
          status: response.status,
          locations: [],
          message: "",
          requestSucceeded: false,
          success: false,
          messages: [],
        };

        const locationResponse = await this.getLocations(subscriberId);
        if (locationResponse) {
          importResponse.requestSucceeded = response.data.success;
          importResponse.locations = locationResponse.locations;
        }

        if (
          importResponse.requestSucceeded &&
          response.data.messages.length === 0
        ) {
          importResponse.message =
            "Import succeeded. Please verify that all validated addresses appear correct.";
          importResponse.success = true;
        } else {
          importResponse.message = (
            <>
              <p>
                There were some issues importing the file. Please verify the
                information is correct.
              </p>
              <ul>
                {response.data.messages.map((m, k) => (
                  <li key={k}>{m}</li>
                ))}
              </ul>
            </>
          );
        }

        return importResponse;
      })
      .catch((error: AxiosError) => {
        return {
          status: error.response?.status ?? 500,
          locations: [],
          message: this.errorHandler(error, [
            this.STATUS_401_MSG,
            this.STATUS_403_MSG,
            {
              status: 500,
              message:
                "Unable to import locations, please check the file for errors and try again.",
            },
          ]),
          requestSucceeded: false,
          success: false,
          messages: [],
        };
      });
  }

  public formatPhoneNumber(phoneNumber: string): string {
    const first = phoneNumber.substring(0, 3);
    const middle = phoneNumber.substring(3, 6);
    const last = phoneNumber.substring(6);
    return `(${first}) ${middle}-${last}`;
  }
}

export default ApiService;
