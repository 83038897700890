import clsx from "clsx";
import React from "react";

export interface Props {
  fullWidth?: boolean;
  variant?:
    | "solid-yellow"
    | "solid-purple"
    | "outline-yellow"
    | "outline-white"
    | "text-yellow"
    | "text-white"
    | "text-purple";
}

export default function LegalAlert({
  variant = "solid-yellow",
  fullWidth = false,
}: Props) {
  const message = `FOR HPBX OR OTHER MULTI-LINE TELEPHONE SYSTEM CUSTOMERS: You are responsible
  for reporting and updating your Dispatchable Location information (which
  is defined as a location delivered to the PSAP with a 911 call that
  consists of a validated street address of the calling party, plus
  additional information such as suite, apartment or similar information
  necessary to adequately identify the location of the calling party) which
  will be the Registered Location unless moved. Dispatchable Location
  information may be updated either by contacting us or by utilizing the
  MetroNet E911 Management Tool. Updates may take 1-2 business days to
  process. Updates may only be made or requested by Authorized Users. You
  understand that if you register using an incorrect physical address and
  location of your equipment, your emergency calls may be routed to the
  incorrect emergency service provider.`;

  const getStyle = () => {
    const width = fullWidth ? "w-full" : "lg:w-1/2";
    const border = `p-3 border-2 border-gray-950 rounded-lg`;
    switch (variant) {
      case "solid-yellow":
        return `text-yellow-1 bg-gray-950 ${width} ${border}`;
      case "solid-purple":
        return `text-purple-1 bg-gray-950 ${width} ${border}`;
      case "outline-white":
        return `text-white ${width} ${border}`;
      case "outline-yellow":
        return `text-yellow-1 ${width} ${border}`;
      case "text-yellow":
        return `text-yellow-1 ${width}`;
      case "text-purple":
        return `text-purple-1 ${width}`;
      case "text-white":
        return `text-white ${width}`;
    }
  };

  return (
    <div className="flex flex-row justify-center mb-5">
      <div className={clsx("text-sm", getStyle())}>{message}</div>
    </div>
  );
}
