import React from "react";
import TE911Location from "../../../models/TE911Location";
import AlertIcon from "../AlertIcon";
import styles from "./LocationCard.module.css";

export interface Props {
  location: TE911Location;
  onClick: (location: TE911Location) => void;
}

export default function LocationCard({ location, onClick }: Props) {
  const haveValue = (val: string | null | undefined) =>
    val !== null && val !== undefined && val !== "";

  const haveDetails = haveValue(location.additionalInfo);
  const haveAddress =
    haveValue(location.address1) &&
    haveValue(location.city) &&
    haveValue(location.state) &&
    haveValue(location.zip);
  const haveAddress2 = haveValue(location.address2);

  const renderAddress = () => {
    if (!haveAddress)
      return <div className={styles.warning}>No address available.</div>;

    return (
      <>
        <div className={styles.textContent}>{location.address1}</div>
        {haveAddress2 && (
          <div className={styles.textContent}>{location.address2}</div>
        )}
        <div className={styles.textContent}>
          {location.city}, {location.state} {location.zip}
        </div>
        {!haveAddress2 && <div className={styles.textContent} />}
      </>
    );
  };

  const renderDetails = () => {
    if (!haveDetails)
      return <div className={styles.warning}>No details available.</div>;

    return <div className={styles.textContent}>{location.additionalInfo}</div>;
  };

  return (
    <div className={styles.card} onClick={() => onClick(location)}>
      <div className={styles.cardTitle}>
        {(!haveDetails || !haveAddress) && (
          <div className={styles.warning}>
            <AlertIcon />
          </div>
        )}
        <div>{location.phoneNumberFormatted}</div>
      </div>
      <div className={styles.cardContent}>
        <div className={styles.column1}>
          <div className={styles.label}>Address</div>
          {renderAddress()}
        </div>
        <div className={styles.column2}>
          <div className={styles.label}>Detailed Location</div>
          {renderDetails()}
        </div>
      </div>
    </div>
  );
}
