import React from "react";
import useMediaQuery from "../../../hooks/useMediaQuery";

import Business from "./Business";
import MobileBusiness from "./MobileBusiness";

/**
 * Modified from original component
 * Changed to only return business logo.
 */

export default function Logo(): JSX.Element | null {
  const isDesktopViewport = useMediaQuery("md");
  return isDesktopViewport ? <Business /> : <MobileBusiness />;
}
