import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faFileUpload, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export interface UploadIconProps {
  /** When isUploading is true, displays a spinner. */
  isUploading: boolean;
}

export default function UploadIcon({ isUploading }: UploadIconProps) {
  if (isUploading) {
    return <FontAwesomeIcon spin={true} icon={faCircleNotch} />;
  }

  return <FontAwesomeIcon icon={faFileUpload} />;
}
