import React, { ReactChild, useState } from "react";

export interface ModalProps {
  children: ReactChild;
}

function useModal() {
  const [modalOpen, setModalOpen] = useState(false);

  const show = () => {
    setModalOpen(true);
  };

  const hide = () => {
    setModalOpen(false);
  };

  const RenderModal = (props: ModalProps) => {
    if (modalOpen) {
      return <>{props.children}</>;
    }

    return null;
  };

  return { show, hide, RenderModal };
}

export default useModal;
