import React from "react";

export default function MetronetBusiness() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.19276 2.8999H10.657C11.3711 2.8999 11.9499 3.47873 11.9499 4.19276V20.9999H4.19276C3.47873 20.9999 2.8999 20.4211 2.8999 19.7071V4.19276C2.8999 3.47873 3.47873 2.8999 4.19276 2.8999Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2426 11.9499H19.7069C20.4209 11.9499 20.9997 12.5287 20.9997 13.2427V19.707C20.9997 20.4211 20.4209 20.9999 19.7069 20.9999H13.2426C12.5285 20.9999 11.9497 20.4211 11.9497 19.707V13.2427C11.9497 12.5287 12.5285 11.9499 13.2426 11.9499Z"
        stroke="currentColor"
        strokeWidth="1.5"
      />
      <path
        d="M7.4248 8.71777H11.9498"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.4248 12.5963H11.9498"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.4248 16.4749H11.9498"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4751 16.4749H21.0001"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.4751 7.42493V11.9499"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
