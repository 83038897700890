import React, { useContext } from "react";
import { useCallback, useState } from "react";
import LoadingIndicator from "../components/modules/LoadingIndicator";
import UserLoginForm from "../components/modules/UserLoginForm";
import AlertContext from "../contexts/AlertContext";
import ApiService from "../services/ApiService";
import history from "../services/history";

export interface AdminLoginPageProps {
  onSignIn(): void;
}

function AdminLoginPage(props?: AdminLoginPageProps) {
  const { setAlert } = useContext(AlertContext);
  const apiService = useCallback(() => new ApiService(), []);
  const [username, setUsername] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleUserLogin = async (user: string, pass: string) => {
    setAlert(null);
    setLoading(true);
    setUsername(user);
    const response = await apiService().signIn({
      user,
      pass,
      authType: "MetroUser",
    });
    if (response.signedIn) {
      setLoading(false);

      if (props?.onSignIn) {
        props.onSignIn();
      }

      history.push("/admin/");
    } else {
      setAlert(response);
      setLoading(false);
    }
  };

  if (loading) return <LoadingIndicator loading={loading} />;

  return (
    <div className="grid grid-cols-12 mt-10">
      <section className="p-3 col-start-2 col-span-10 lg:col-start-5 lg:col-span-4 bg-gray-950 text-white rounded-md">
        <div className="text-lg text-center w-full">E911 Management</div>
        <UserLoginForm
          username={username}
          onSubmit={(username, password) => handleUserLogin(username, password)}
        />
      </section>
    </div>
  );
}

export default AdminLoginPage;
