import * as React from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";
import { User } from "../../../models/User";

interface ProtectedRouteProps extends RouteProps {
  component: any;
  user: User | null | undefined;
  isAdminOnly?: boolean;
  redirect?: string;
}

function ProtectedRoute(props: ProtectedRouteProps) {
  const { component: Component, user, isAdminOnly, redirect, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        var adminCheckPassed =
          !isAdminOnly || (isAdminOnly && user?.role.includes("Admin"));

        if (user && adminCheckPassed) {
          return <Component {...routeProps} />;
        } else {
          return <Redirect to={redirect ?? "/login"} />;
        }
      }}
    />
  );
}

export default ProtectedRoute;
