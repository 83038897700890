import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export interface ExportIconProps {
  /** When isExporting is true, displays a spinner. */
  isExporting: boolean;
}

export default function ExportIcon({ isExporting }: ExportIconProps) {
  if (isExporting) return <FontAwesomeIcon spin icon={faCircleNotch} />;
  return <FontAwesomeIcon icon={faFileExport} />;
}
